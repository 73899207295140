import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { couponPost, membershipGet, membershipPost, membershipUpdate } from "../../../../utils/global.apis";
import Select from "../../../elements/Select";
import Content from "./Content";
import styles from "./Form.module.css";

const Form = (props)=>{

    //indian / non-indian
    const [categoryType,setCategoryType] = useState('Indian');

    //percentage / flat
    const [answerType,setAnswerType] = useState('Percentage');

    //active / inactive
    const [isActive,setIsActive] = useState('Active');

    //discount
    const [duration,setDuration]=useState("");

    //code
    const [price,setPrice]=useState("");

    //to handle category type change
    const categoryTypeChangeHandler= (e)=>{
        setCategoryType(e.target.value);
    }


    //On answer i.e input type change
    const answerTypeChangeHandler= (e)=>{
        //set state
        setAnswerType(e.target.value);

    }

    const isActiveChangeHandler = (e)=>{
        setIsActive(e.target.value);
    }
    
    //To pre load fields in case of edit
    useEffect(()=>{
        if(props.mode!="edit") return;
    },[]);

    //When clicked on CTA i.e BTN
    const postQuestionHandler = async (e)=>{
        try{
        
        //Translate local variables acc to API body
        let type;
        if(categoryType=="Indian") type=true;
        if(categoryType=="Non-Indian") type=false;
    
        let discountType=answerType;

        let isActiveVar = isActive=="Active"?true:false;

        const data = {
            forIndia:type,
            discountType,
            isActive:isActiveVar,
            code:price,
            discount:duration
        };

        //Decide is it update or create
        let res;
        if(props.mode!="edit")
        res = await couponPost(data);
        
        if(!res)  throw "Something went wrong!";
       
        //fetch coupons
        if(props.refresh) props.refresh();
        
        //close form popup
        props.onClose();

        if(props.mode!="edit")
        alert("Coupon added succesfully!");

        props.refreshFn();

        }catch(err){
            alert(err);
            console.log(err);
        }
    }

    return <div className={styles["container"]}>
        <div className={styles["close"]} onClick={props.onClose}><AiFillCloseCircle/></div>
        <div className={styles["heading"]}>{props.mode!="edit"?"Add a question":"Edit a question"}</div>
               {
                answerType=="Percentage"?
                <></>
                :
                <div className={styles["select_answer"]}>
                <div className={styles["sa_text"]}>Select Category</div>
                    <div className={styles["sa_inp"]}>
                        <Select options={['Indian','Non-Indian']} onChange={categoryTypeChangeHandler} init={categoryType}/>
                    </div>
                </div>
               }
                <div className={styles["select_answer"]}>
                <div className={styles["sa_text"]}>Select Status</div>
                    <div className={styles["sa_inp"]}>
                        <Select options={['Active','Inactive']} onChange={isActiveChangeHandler} init={isActive}/>
                    </div>
                </div>
                <div className={styles["select_answer"]}>
                    <div className={styles["sa_text"]}>Select Coupon Type</div>
                    <div className={styles["sa_inp"]}>
                        <Select options={['Percentage','Flat']} onChange={answerTypeChangeHandler} init={answerType}/>
                    </div>
                </div>
                <div className={styles["select_answer"]}>
                <div className={styles["sa_text"]}>Enter Discount</div>
                    <div className={styles["sa_inp"]}>
                        <input type="number" value={duration} onChange={(e)=>setDuration(e.target.value)}/>
                    </div>
                </div>
                <div className={styles["select_answer"]}>
                <div className={styles["sa_text"]}>Enter Code</div>
                    <div className={styles["sa_inp"]}>
                        <input type="text" value={price} onChange={(e)=>setPrice(e.target.value)}/>
                    </div>
                </div>
        <div className={styles["cta"]}>
            <div className={styles["btn"]} onClick={postQuestionHandler}>{props.mode!="edit"?"Submit":"Save"}</div>
        </div>
    </div>;
}
export default Form;