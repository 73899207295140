import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { membershipDelete } from "../../../../utils/global.apis";
import Form from "../Form";
import styles from "./Card.module.css";
import UploadMembershipImage from "../UploadMembershipImage/index"

const Card = (props)=>{
    const [showForm,setShowForm] = useState(false);
    const [showUploadForm,setUploadShowForm] = useState(false);

    //to delete question on delete btn click
    function deleteQuestionHandler(e){
        if(!props.data._id) return;
        
        membershipDelete(props.data._id)
        .then(res=>{
            //fetch questionare again
            alert("Question removed succesfully!");
            if(props.refreshFn) props.refreshFn();
        })
        .catch(err=>console.log(err));
    }

    const toggleShowForm = (e)=>setShowForm(state=>!state);
    const toggleUploadShowForm = (e)=>setUploadShowForm(state=>!state);
    
    return <div className={styles["container"]}>
        {showForm && <div className={styles["form"]}>
        <div className={styles["form_inner"]}>
            <Form onClose={toggleShowForm} refreshFn={props.refreshFn} mode="edit" id={props.data._id}/>
        </div>
    </div>}
    {showUploadForm && <div className={styles["form"]}>
        <div className={styles["form_inner"]}>
            <UploadMembershipImage onClose={toggleUploadShowForm} refreshFn={props.refreshFn} mode="edit" id={props.data._id}/>
        </div>
    </div>}
        <div className={styles["content"]}>
            <div className={styles["inner-card"]}>
                <div><b>English</b></div>
                <div>
                    <b>Name: </b>
                    {props.data.name}
                </div>
                <div>
                    <b>Includes:</b>
                </div>
                <div>
                    {props.data.includes && props.data.includes.map(el=>{
                        return <div><AiOutlineCheck/> {el}</div>
                    })}
                </div>
            </div>
            {props.data.type!="OI" && <div className={styles["inner-card"]}>
                <div><b>Hindi</b></div>
                <div>
                    <b>Name: </b>
                    {props.data.hindiName}
                </div>
                <div>
                    <b>Includes:</b>
                </div>
                <div>
                    {props.data.hindiIncludes && props.data.hindiIncludes.map(el=>{
                        return <div><AiOutlineCheck/> {el}</div>
                    })}
                </div>
            </div>}
            <div className={styles["data-contain"]}>
                <div className={styles["data"]}>
                    <div><b>Price:</b> {props.data.price}</div>
                    <div><b>Currency type:</b> {props.data.currencyType}</div>
                </div>
                <div className={styles["data"]}>
                    <div><b>Type:</b> {props.data.type=="I"?"Indian":"Non-Indian"}</div>
                    <div><b>Validity:</b> {props.data.duration} days</div>
                </div>
            </div>
            <div>
                <div className={styles["cta"]}>
                    <div className={styles["remove"]}>
                        <div onClick={deleteQuestionHandler}>Remove</div>
                    </div>
                    <div className={styles["edit"]}>
                        <div onClick={toggleShowForm}>Edit</div>
                    </div>
                    <div className={styles["edit"]} style={{marginLeft:10}}>
                        <div onClick={toggleUploadShowForm}>Uplaod Image</div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default Card;