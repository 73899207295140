import styles from "./RefundPolicy.module.css";

const RefundPolicy = (props)=>{
    return <div className={styles["container"]}>
        <h3>Return Policy</h3>
        Last updated January 27,2023

        <h3>Refunds</h3>
        All sales are final and no refund will be issued.

        <h3>Questions</h3>
        If you have any questions concerning our return policy, please contact us at: healinroots@gmail.com
    </div>;
}

export default RefundPolicy;