import { useSearchParams } from "react-router-dom";
import styles from "./Table.module.css";
const TableHeader = (props)=>{
    return <thead class="thead-dark">
        <tr>
            {props.data && props.data.map((el,idx)=>{
                return <th className={styles["member-heading"]} scope="col">{el}</th>
            })}
        </tr>
    </thead>
}

const TableRow = (props)=>{
    // console.log("data:",props);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const redirectToDetails = (e)=>{
        // setSearchParams(state=>{id:props.data?props.data[0]:0,..state});

        setSearchParams(state=>{
            let obj={...state};
            // console.log(obj);
            obj.id=props.id?props.id:0;
            obj.activeIdx=searchParams.get('activeIdx');
            return obj;
        });
        props.setShowDetails(true);
        props.setRedirect(!props.redirect)
    }

    const redirectToCQ = (e)=>{
        // setSearchParams(state=>{id:props.data?props.data[0]:0,..state});

        setSearchParams(state=>{
            let obj={...state};
            // console.log(obj);
            obj.id=props.id?props.id:0;
            obj.activeIdx=searchParams.get('activeIdx');
            return obj;
        });

        props.setShowCQ(true);
        props.setRedirect(!props.redirect)
    }

    const redirectToCA = (e)=>{
        // setSearchParams(state=>{id:props.data?props.data[0]:0,..state});

        setSearchParams(state=>{
            let obj={...state};
            // console.log(obj);
            obj.id=props.id?props.id:0;
            obj.activeIdx=searchParams.get('activeIdx');
            return obj;
        });

        props.setShowCA(true);
        props.setRedirect(!props.redirect)
    }
    return <tr>
        {props.data && <th  className={styles["member-heading"]} scope="row">{props.data[0]}</th>}
        {
            props.data && props.data.map((el,idx)=>{
                if(idx==0) return <></>;

                if(idx==props.data.length-5 && props.makeLastBtn) return <td className={styles["member-data"]}><div className={styles["link"]} onClick={redirectToCQ}>{el}</div></td>
                if(idx==props.data.length-4 && props.makeLastBtn) return <td className={styles["member-data"]}><div className={styles["link"]} onClick={redirectToCA}>{el}</div></td>
                if(idx==props.data.length-3 && props.makeLastBtn) return <td className={styles["member-data"]}><div className={styles["link"]} onClick={redirectToDetails}>{el}</div></td>
                if(idx==props.data.length-2 && props.makeLastBtn) return <td className={styles["member-data"]}><div className={styles["link"]} onClick={()=>{window.open(`mailto:${el}`)}}>{el}</div></td>

                // if(idx==4) return <td><div className={styles["tag"]}>{el}</div></td>
                return <td className={styles["member-data"]}>{el}</td>
            })
        }
    </tr>
}
const Table=(props)=>{
    let rowData = props.data?props.data:[];
    if(rowData) rowData=rowData.length>1?rowData.slice(1):rowData;

    if(props.data.length<=1)return <div>{props.notFoundMsg}</div>;

    return <div className={styles["container"]}>
    <div className={styles["wrapper"]}>
    <div className={styles["content"]}>
       <table class="table table-light">
            <TableHeader data={props.data?props.data[0]:[]}/>
            <tbody>
                {
                    rowData && rowData.map((el,idx)=>{
                        return <TableRow data={el} setRedirect={props.setRedirect} redirect={props.redirect} setShowDetails={props.setShowDetails} makeLastBtn={props.makeLastBtn} setShowCQ={props.setShowCQ} setShowCA={props.setShowCA} id={props.ids?props.ids[idx]:0}/>
                    })
                }
            </tbody>
        </table>
    </div>
    </div>
    {props.pagination==false && <div className={styles["options"]}>
        <div className={styles["left"]}></div>
        <div className={styles["right"]}>
            {props.pageNo > 1 && <div onClick={props.prevPageHandler}>Prev</div>}
            <div onClick={props.nextPageHandler}>Next</div>
        </div>
    </div>}
    </div>
}
export default Table