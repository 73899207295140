import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import LeftArrow from "../../../assets/left-arrow.svg";
import RightArrow from "../../../assets/right-arrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Blog.css"; 
import axios from "axios";
import styles1 from './Services.module.css';
import { Link } from "react-router-dom";
import styles from './Blog.module.css';
import parse from 'html-react-parser';

const Blog = () => {


  const [allData, setAllData] = useState([]);

  const extractImgTags = (htmlString) => {
    const imgRegex = /<img[^>]*>/g; // Regular expression to match <img> tags
    const imgTags = htmlString.match(imgRegex); // Extract <img> tags
    return imgTags || [];
  };

  const handleBlog = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/blog?domain=abeautifulmess.com&maxResults=100`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Blog Fetched successfully", response.data.data.items);
      setAllData(response.data.data.items);
    } catch (error) {
      console.log("Error fetching blogs", error);
    }
  };

  useEffect(() => {
    handleBlog();
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className={styles1["container"]}>
      <h2 className={styles1["heading"]}>Our Blogs</h2>

    <div className={styles.container}>
      <div className={styles.cardContainer}>
        {allData?.slice(0, 3)?.map((item, index) => (
          <div key={index} className={styles.card}>
            <div> {parse(extractImgTags(item?.content)[0])}</div>
            <p className={styles.cardTitle}>{truncateText(item.title, 40)}</p>
            {/* <p className={styles.cardContent}>{truncateText(item.description, 100)}</p> */}
            <Link to={`/blog/${item?.blog?.id}/${item.id}`} className={styles.readMore}>Read More</Link>
          </div>
        ))}
      </div>
      <Link to="/all-blogs" className={styles.showMore}>Show More</Link>
    </div>

    </div>

  );
};

export default Blog;
