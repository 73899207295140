import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartQuestionSequenceByType } from "../../../store/ChartQuestionSequence/ChartQuestionSequence.slice";
import { fetchDietUsers, submitDietChart } from "../../../utils/global.apis";
import Select from "../../elements/Select";
import styles from "./ChartAttempt.module.css";
import Question from "./Question";
import AutocompleteSelect from "../../elements/AutocompleteSelect";

const ChartAttempt = (props) => {
    const [answerType, setAnswerType] = useState('Indian - English');
    const [refresh, setRefresh] = useState(true);
    const [usersAllData, setUsersAllData] = useState([]);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState('Select User');
    const [idx, setIdx] = useState(null);
    const [answers, setAnswers] = useState([['']]);
    // console.log(user);
    // console.log(idx);
    // console.log("answerType",answerType);
    // console.log("answers:",answers);

    const list = useSelector(state => state.chartSequence.chartQuestionSequence);
    // console.log(list);

    useEffect(() => {
        setAnswers(Array(list.length).fill(['']));
    }, [list]);

    const dispatch = useDispatch();

    // useEffect(()=>console.log(user),[user]);
    const doRefresh = () => setRefresh(state => !state);

    // console.log(user);
    useEffect(() => {
        fetchDietUsers().then(res => {

            // console.log(res.data.data);
            let usersData;


            if (res.data.data)
                usersData = res.data.data;

            usersData = usersData.map(el => {
                let phone = el.isParent ? el?.phone : el?.parent?.phone
                return {
                    name: `${el.name}-${phone}`,
                    id: el._id
                }
            })

            console.log(usersData)

            // usersData = usersData.filter(el=>)

            setUsersAllData(usersData ? usersData : []);
            setUsers(usersData ? usersData.map(el => el.name) : []);
            setAnswers([['']]);
            setUser('Select User');
            // console.log("refresh",refresh);
        })
            .catch(err => {
                console.log(err);
            });


        let cat;
        if (answerType == "Indian - Hindi") cat = "I-H";
        if (answerType == "Indian - English") cat = "I-E";
        if (answerType == "Non - Indian") cat = "OI";
        dispatch(fetchChartQuestionSequenceByType(cat));
    }, [refresh, answerType]);

    const categoryChangeHandler = (e) => { setUser(e.target.value); setIdx(e.target.meta) };

    const onAnswerChange = (val, idx) => {
        setAnswers(state => {
            let arr = [...state];
            if (arr.length - 1 < idx) {
                arr = [...arr, ...Array(idx + 1 - arr.length).fill('')];
            }
            arr[idx] = val;
            return arr;
        });
    }

    const answerTypeChange = (e) => { setAnswerType(e.target.value); doRefresh(); }

    const saveAttempt = (e) => {
        // console.log(answers);
        const body = {
            answers: []
        }

        // console.log(list);

        // if(answers.length < list.length) return "Answer all questions!";

        // let flag = true;
        body.answers = list.map((el, idx) => {

            // if(answers[idx].findIndex(el=>el!="")==-1) flag=false;


            let answer = []
            if (answers[idx]) {
                if (typeof answers[idx] == "object") {
                    answer = answers[idx]?.filter(el => el !== "")
                } else {
                    answer = answers[idx]
                }
            }

            return {
                question: el._id,
                answer: answer
            }
        });

        // if(!flag) return alert("Answer all questions!");

        let usr = usersAllData.find(el => el.name == user);

        if (!usr) return alert("please select a vaild user");

        submitDietChart(body, usersAllData[idx].id).then((res) => {
            // console.log(res);
            alert("Diet chart attempt submitted!");
            doRefresh();
        })
            .catch(err =>
                console.log(err)
            );

    };

    return <><div className={styles["container"]}>
        <div className={styles["content"]}>
            <div className={styles["headings"]}>
                <h1>Make A Chart</h1>
            </div>
            <div className={styles["inner"]}>
                <div className={styles["upper"]}>
                    <div className={styles["nav"]}>
                        <div className={styles["left"]}>
                            <div className={styles["category"]} style={{ width: "300px" }}>
                                <AutocompleteSelect init={user} options={users} onChange={categoryChangeHandler} />
                            </div>
                            {/* <div className={styles["category"]}>
                                <Select init={'Indian - English'} options={['Indian - English','Indian - Hindi','Non - Indian']} onChange={answerTypeChange}/>
                            </div> */}
                        </div>
                        <div className={styles["right"]}>
                            {/* <div onClick={toggleShowForm}>Add Question</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles["lower"]}>
                <div className={styles["answer"]}>
                    <div>
                        {refresh && list &&
                            list.map((el, idx) => <Question onAnswerChange={onAnswerChange} idx={idx} key={idx} text={el?.qtype == "Text"} question={el?.question[0]} />)
                        }
                    </div>
                    <div>
                        {!refresh && list &&
                            list.map((el, idx) => <Question onAnswerChange={onAnswerChange} idx={idx} key={idx} text={el?.qtype == "Text"} question={el?.question[0]} />)
                        }
                    </div>
                    {/* <Question text={true} options={[]}/>
                    <Question text={false} options={["Lunch","Dinner"]}/> */}
                </div>
            </div>
        </div>
    </div>
        <div className={styles["btn"]} onClick={saveAttempt}>Save</div>
    </>;
}

export default ChartAttempt;