import * as React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Ckeditor({description,setDescription}) {
    const editorConfig = {
        toolbar: {
          items: [
            // Other toolbar items here
            'bold',
            'italic',
            'underline',
            'bulletedList',
            "numberedList"
            // Remove 'image' from the items list to hide the image button
            // 'image',
          ],
        },
      };
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        config={editorConfig}
        data={description}
        onReady={(editor) => {
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setDescription(data)
        }}
      />
    </div>
  );
}
