import React, { useEffect, useState } from 'react';
import styles from './Services.module.css';
import axios from 'axios';

function FAQList() {
    const [faq, setFaq] = useState([]);
    const [hoveredCard, setHoveredCard] = useState(null);

      const handleFAQ = async() => {
        try {
            const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/faq/all`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
            console.log("FAQ Fetched successfully", response.data.data.faqsData)
            setFaq(response.data.data.faqsData);
        } catch (error) {
            console.log("Error fetching FAQ", error);
        } 
      }

      useEffect(() => {
        handleFAQ();
      },[]);


  return (
    <div className={styles["container"]}>
    <h3 className={styles["heading"]}>Frequently Asked Questions</h3>
  
          <div style={{ width: '100%', margin: '0 auto' }}>
      {faq.map((faq, index) => (
        <div
          key={index}
          onMouseEnter={() => setHoveredCard(index)}
          onMouseLeave={() => setHoveredCard(null)}
          style={{
            width: '100%',
            padding: '20px',
            margin: '10px 0',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: hoveredCard === index ? '#04686C' : '#fff',
            color: hoveredCard === index ? '#fff' : '#000',
            transition: 'background-color 0.3s ease, color 0.3s ease',
            cursor: 'pointer',
          }}
        >
          <h6 style={{fontWeight:"400"}}>{faq?.question}</h6>
          {hoveredCard === index && (
            <p style={{color:"#fff"}}>{faq?.answer}</p>
          )}
        </div>
      ))}
    </div>
  </div>  )
}

export default FAQList

