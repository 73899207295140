import logo from './logo.svg';
import './App.css';

//npm module imports
import {RouterProvider} from "react-router-dom";

//custom imports 
import router from "./utils/browser-router";
function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
