import { useEffect, useState } from "react";
import styles from "./Hero.module.css";
import logo from "./../../../assets/logo.png";
import hero1 from "./../../../assets/hero1.jpg";
import hero2 from "./../../../assets/hero2.jpeg";
import hero3 from "./../../../assets/hero3.jpeg";
import hero4 from "./../../../assets/hero4.jpeg";
import hero5 from "./../../../assets/hero5.jpeg";
import hero6 from "./../../../assets/hero6.jpeg";
import doctor from "./../../../assets/doctor.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
// import "./style.css"


const images = [
{
    id: 1,
    src: doctor,
    alt: "Experts"
},
{
    id: 2,
    src: hero1,
    alt: "Ayurveda"
},
{
    id: 3,
    src: hero2,
    alt: "Image 2 "
},
{
    id: 4,
    src: hero3,
    alt: "Image 2 "
},
{
    id: 5,
    src: hero4,
    alt: "Image 2 "
},
{
    id: 6,
    src: hero5,
    alt: "Image 2 "
},
{
    id: 7,
    src: hero6,
    alt: "Image 2 "
},
// {
//     id: 3,
//     src: hero3,
//     alt: "Image 3"
// }
];



const Hero = (props)=>{
   const settings = {
    infinite: true,
    // dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
  autoplaySpeed: 3000,
   
  };

  const [allData, setAllData] = useState([]);

const handleHeroSection = async() => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/website/home`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const allData = response.data.data.homePageData;
      setAllData(allData);
    } catch (error) {
      console.log("Error fetching Hero Section", error);
    } 
  };

  useEffect(() => {
    handleHeroSection();
  },[]);

    return <div className={styles["container"]}>
        <div className={styles["left"]}>
            <div className={styles["content"]}>
                <h1>{allData?.hero_heading}</h1>
                <p>{allData?.hero_sub_heading}</p>
                <div className={styles["btns"]}>
                    <div className={styles["active"]} onClick={()=>{window.open(allData?.hero_chat_with_us,"_blank")}}>Chat with us</div>
                    <div className={styles["passive"]} onClick={()=>{
                        window.scrollTo(0, document.body.scrollHeight);
                    }}>{allData?.hero_know_more ? allData?.hero_know_more : "Know more"}</div>
                </div>
            </div>
        </div>
        <div className={styles["right"]}>
            <div className={styles["contain"]}>
                <div className="imgslider">
                    <Slider {...settings}>
                    {allData?.hero_images?.map((item) => (
                        <div className={styles["imgbox"]} >
                        <img src={item.link}  alt="image" />
                        {/* <p>{item.alt}</p> */}
                        </div>
                    ))}
                    </Slider>
                </div>
            </div>
        </div>
    </div>;
}
export default Hero;