import { useState } from "react";
import styles from "./Select.module.css";
import { AiFillCaretDown } from 'react-icons/ai';
import { useEffect } from "react";
import Select from 'react-select'

const options = [

]
const AutocompleteSelect = (props) => {

    const [option,setOption] = useState([])

    useEffect(()=>{
        let temp = props.options.map((el,idx)=>{return {label:el,value:idx}})
        setOption(temp)
    },[props.options])

    const handleSelect =(e) => {
        props.onChange({ target: { value:e.label, meta: e.value } });
    }

    return <div className={styles["container"]}>
        {/* <div className={styles["main"]} onClick={(e) => setExpand(state => !state)}><div>{selected}</div><div className={expand ? styles["rotate"] : ""} ><AiFillCaretDown /></div></div>
        {expand && <div className={styles["options"]}>
            {props.options && props.options.map((el, idx) => {
                return <div className={styles["option"]} onClick={() => handleSelect(el, idx)}>
                    {el}
                </div>
            })}
        </div>} */}
       
       <Select options={option} onChange={(e)=>{handleSelect(e)}} placeholder="Select User"/>
    </div>
}
export default AutocompleteSelect;