import styles from "./Inspiration.module.css";
import rajivdixit from "./../../../assets/rajivdixit.jpg";
import { useEffect, useState } from "react";
import axios, { all } from "axios";
const Inspiration = (props) => {
  const [allData, setAllData] = useState({});
  const handleHeroSection = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/website/home`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const allData = response.data.data.homePageData;
      setAllData(allData);
    } catch (error) {
      console.log("Error fetching Hero Section", error);
    }
  };

  useEffect(() => {
    handleHeroSection();
  }, []);
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div
          className={styles["img"]}
          style={{ backgroundImage: `url(${allData?.inspiration_image})` }}
          onClick={() => {
            window.open(
              "https://m.facebook.com/groups/967687893436035/?ref=share&mibextid=ykz3hl",
              "_blank"
            );
          }}
        >
          {/* <img src={rajivdixit} alt="rajiv dixit"/> */}
        </div>
        <div className={styles["details"]}>
          <div className={styles["detail"]}>
            <h2 className={styles["heading"]}>Our Inspiration</h2>
            <div>{allData?.inspiration_text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Inspiration;
