import { useRef } from "react";
import styles from "./OtpInput.module.css";

const OtpInput = (props)=>{

    const inp1ref = useRef();
    const inp2ref = useRef();
    const inp3ref = useRef();
    const inp4ref = useRef();
    const inp5ref = useRef();
    const inp6ref = useRef();

    const arr=[inp1ref,inp2ref,inp3ref,inp4ref,inp5ref,inp6ref];

    function keyUpPressedOn(e,idx){
       
        if(idx>=0&&idx<5)
        if(e.key=="Enter"||arr[idx].current.value.length>1){
                arr[idx+1].current.focus();
                arr[idx+1].current.value=arr[idx].current.value[1];
                arr[idx].current.value=arr[idx].current.value[0];

        }

        if(idx==5&&arr[idx].current.value.length>1)
        arr[idx].current.value=arr[idx].current.value[0];

        if(idx>0&&idx<6)
        if(e.key=="Backspace"&&arr[idx].current.value.length==0){
            arr[idx-1].current.focus();
        }

        props.setOTP(()=>{
            return inp1ref.current.value +""+inp2ref.current.value +""+inp3ref.current.value +""+inp4ref.current.value +""+inp5ref.current.value +""+inp6ref.current.value +"";
        })
    }

    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <input type="number" ref={inp1ref} onKeyUp={e=>{
                keyUpPressedOn(e,0);
            }}/>
            <input type="number" ref={inp2ref} onKeyUp={e=>{
                keyUpPressedOn(e,1);
            }}/>
            <input type="number" ref={inp3ref} onKeyUp={e=>{
                keyUpPressedOn(e,2);
            }}/>
            <input type="number" ref={inp4ref} onKeyUp={e=>{
                keyUpPressedOn(e,3);
            }}/>
            <input type="number" ref={inp5ref} onKeyUp={e=>{
                keyUpPressedOn(e,4);
            }}/>
            <input type="number" ref={inp6ref} onKeyUp={e=>{
                keyUpPressedOn(e,5);
            }}/>
        </div>
    </div>
}
export default OtpInput;