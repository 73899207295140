import { useNavigate } from "react-router-dom";
import { login } from "../../../utils/global.apis";
import Form from "./Form";
import styles from "./Login.module.css";
const Login = (props)=>{

    const navigate = useNavigate();

    function containsOnlyNumbers(str) {
        return /^\d+$/.test(str);
    }

    function isValidEmail(str){
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str);
    }

    const loginHandler = async (val)=>{
        try{
        const value = val.phone;
        const isPhone = containsOnlyNumbers(value);
        const isEmail = isValidEmail(value);
        
        if(!isPhone&&!isEmail){
            return alert("Enter a valid email address or phone number");
        }

        let res;
        if(isPhone){
            res = await login({phone:value});
        }
        else{
            res = await login({email:value});
        }
        // const res = await login({phone:value});
        if(res.data.success)
            if(isPhone)
            return navigate('/admin/otp?source=phone&data='+value);
            else if(isEmail)
            return navigate('/admin/otp?source=email&data='+value);
        else
        return Promise.reject("err");


        }catch(err){
            alert("Opps! Something went wrong");
        }
    }

    return <div className={styles["container"]}>
        <Form loginHandler={loginHandler}/>
    </div>
}
export default Login;