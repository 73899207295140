import { useEffect, useState } from "react";
import { adminAxios } from "../../../utils/axios/admin.axios";
import Switch from "../../elements/Switch";
import styles from "./Coupon.module.css";
import Table from "./Table";
import Form from "./Form";
import { couponDelete, couponEdit } from "../../../utils/global.apis";

const Coupon = (props)=>{ 
    const [tableData,setTableData]=useState([["Id","Code","Discount","Type","Indian","Active","Delete"]]);
    const [showForm,setShowForm] = useState(false);
    const [refresh,setRefresh] = useState(false);

    const refreshFn = ()=>setRefresh(state=>!state);

    const toggleShowForm = () => setShowForm(state=>!state);

    const deleteCouponByCode = (code) =>{
        couponDelete(code)
        .then(res=>{alert("Coupon deleted!");refreshFn();})
        .catch(err=>{alert("Ops! Something went wrong");refreshFn();})
    }

    const editCoupon = (code,data)=>{
        couponEdit(code,data)
        .then(res=>{alert("Edit succesfull!");refreshFn();})
        .catch(err=>{alert("Ops! Something went wrong");refreshFn();})
    }

    useEffect(()=>{
        adminAxios.get("/view-coupon")
        .then(res=>{
            const data = [];

            if(!res.data) return ;

            res.data.data.forEach(el=>{
                data.push([el._id,el.code,el.discount,el.discountType,el.forIndia,el.isActive,"Delete"]);
            });

            setTableData([["Id","Code","Discount","Type","Indian","Active","Delete"],...data]);
        })
        .catch(err=>console.log(err));
    },[refresh]);

    return <div className={styles["container"]}>
        {showForm && <div className={styles["form"]}>
            <div className={styles["form_inner"]}>
                <Form onClose={toggleShowForm} refreshFn={refreshFn}/>
            </div>
        </div>}
        <div className={styles["content"]}>
            <div className={styles["headings"]}>
                    <h1>Coupon</h1>
            </div>
            <div className={styles["inner"]}>
            <div className={styles["upper"]}>
                <div className={styles["nav"]}>
                        <div className={styles["left"]}>
                            <div className={styles["category"]}>
                                {/* <Select options={CategoryData} onChange={categoryChangeHandler}/> */}
                            </div>
                            {/* <div className={styles["category"]}>
                                <Select options={AnswerTypeData} onChange={onAnswerChange}/>
                            </div> */}
                        </div>
                        <div className={styles["right"]}>
                            <div onClick={toggleShowForm}>Add Coupon</div>
                        </div>
                    </div>
                </div>
                <div className={styles["lower"]}>
                    <Table  data={tableData} deleteCouponByCode={deleteCouponByCode} editCoupon={editCoupon}/>
                </div>
            </div>
        </div>
    </div>;
}

export default Coupon;