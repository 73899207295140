import { formatQuestions } from "../../utils/generic";
import { getQuestionSequence, questionDelete, updateQuestionSequence } from "../../utils/global.apis";

export const fetchQuestionSequenceByTypeHandler = async (__, thunkApi) => {
    try {
        // console.log("data");
    //   const { admin:adminAxios } = thunkApi.extra.apiService;
      const res = await getQuestionSequence(__);
      return formatQuestions(res.data.data.questionSequence,__);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const updateQuestionSequenceByTypeHandler = async (__, thunkApi) => {
    try {
      const res = await updateQuestionSequence(__.questions,__.type);

      return formatQuestions(res.data.data.questionSequence,__);

    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const deleteQuestionFromSequenceByIdHandler = async (__, thunkApi) => {
    try {
      const res = await questionDelete(__);
      return __;
    //   return formatQuestions(res.data.data.questionSequence,__);
    } catch (err) {
      return Promise.reject(err);
    }
  };