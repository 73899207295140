import React from 'react'
import { color } from './Color';
import { getToken } from './AsyncStorage';
import axios from 'axios';
import endUrls from './ApiUrl';

const BoolContainer = (props) => {
    const { type, item, questionData, index, setQuestionData, reRender, setReRender } = props;
    const addQuestion = async (item, index) => {
        const token = await getToken();
        axios.get(`${endUrls.baseUrl}${endUrls.viewQuestion}/${item.question}`,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then((res) => {
                let data = res.data.data
                if (data.type == "bool") {
                    data["options"] = [
                        {
                            text: "Yes"
                        },
                        {
                            text: "No"
                        },
                    ]
                    data["answer"] = ""
                } else if (data.type == "checkbox") {
                    data.tempOptions = []
                    data.tempHindiOptions = []
                    data.options.forEach((e) => {
                        data.tempOptions.push({
                            text: e,
                            checked: false
                        })
                    })
                    data.hindiOptions.forEach((e) => {
                        data.tempHindiOptions.push({
                            text: e,
                            checked: false
                        })
                    })
                    data["answer"] = ""
                } else {
                    data["answer"] = ""
                }
                let newQuestionData = [...questionData]
                newQuestionData.splice(index + 1, 0, data)
                setQuestionData(newQuestionData)
                setReRender(!reRender)
            }).catch((err) => {
                console.log(err)
            })
    }
    const removeQuestion = (item) => {
        let newQuestionData = questionData
        let index = newQuestionData.findIndex(ele => ele._id == item.question)
        if (index == -1) return
        newQuestionData.splice(index, 1)
        setQuestionData(newQuestionData)
        setReRender(!reRender)
    }
    return (
        <div style={styles.checkboxContainer}>
            {
                item?.options?.map((element) => {
                    return (
                        <div >
                            <input type='radio'
                                value={item.answer}
                                checked={item.answer === element.text ? true:false}
                                color={color.Primary}
                                onChange={() => {
                                    const data = questionData
                                    item.answer = element.text
                                    if (item.linkedQuestion?.option == element.text) {
                                        addQuestion(item.linkedQuestion, index)
                                    } else {
                                        removeQuestion(item.linkedQuestion)
                                    }
                                    setQuestionData(data);
                                    setReRender(!reRender)
                                }}
                            />
                            {
                                type == "I-E" || type == "OI" ?
                                    <span >{element.text}</span>
                                    :
                                    element.text=="Yes"?
                                    <span >हाँ</span>
                                    : 
                                    <span >नहीं</span>
                                }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BoolContainer;

const styles = {
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: "center"
    },
    checkboxText: {
        fontSize: 18,
        color: color.Black
    }
}