import styles from "./Download.module.css";
import googleplay from "./../../../assets/googleplay.png";
import appstore from "./../../../assets/appstore.png";
import appimgs from "./../../../assets/appimgs.png";
import axios from "axios";
import { useEffect, useState } from "react";

const Download = (props)=>{
    const [allData, setAllData] = useState([]);

    const handleHeroSection = async() => {
        try {
          const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/website/home`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          const allData = response.data.data.homePageData;
          setAllData(allData);
    
        } catch (error) {
          console.log("Error fetching Hero Section", error);
        } 
      };

      useEffect(() => {
        handleHeroSection();
      },[]);
    return <div className={styles["container"]}>
        
        <div className={styles["right"]}>
            <img src={allData?.app_section_image} alt="App Screens"/>
            {/* <img src={appimgs} alt="App Screens"/> */}
        </div>
        <div className={styles["left"]}>
            <div className={styles["leftContent"]}>
                <div className={styles["heading"]}>
                    <h4>{allData?.app_section_heading}</h4>
                </div>
                <div  className={styles["details"]}>
                    <div  className={styles["subheading"]}>
                        {allData?.app_section_sub_heading}
                    </div>
                    <div className={styles["details__imgs"]}>
                        <img src={googleplay} alt="google play"  onClick={()=>{window.open(allData?.app_section_play_store,"_target")}}/>
                        <img src={appstore} alt="app store" onClick={()=>{window.open(allData?.app_section_app_store,"_target")}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
export default Download;