import { addChart, addUser, adminLogin, allAttempt, approvalUsers, deleteChartQuestion, deleteCoupon, deleteQuestion, deleteTimeSlot, editChartQuestion, editCoupon, editQuestion, fetchAnswers, fetchAppointments, fetchChartAnswers, fetchChartUsers, fetchUsers, getTimeSlot, membershipDeleteRoute, membershipGetRoute, membershipPostRoute, membershipUpdateRoute, otpVerify, paymentApprove, postChartQuestion, postCoupon, postQuestion, postTimeSlot, questionCategoryGet, questionChartSequenceGet, questionChartSequenceUpdate, questionSequenceGet, questionSequenceUpdate, viewChartQuestion, viewQuestion } from "./apis.utils"
import { adminAxios } from "./axios/admin.axios";
import { defaultAxios } from "./axios/default.axios"


//auth
export const login = async ({phone,email})=>{
    try{
    let res;
    if(phone)
    res= await defaultAxios.post(adminLogin,{
        phone:phone
    });
    if(email)
    res= await defaultAxios.post(adminLogin,{
        email:email
    });

    return res;
    }
    catch(err){
        console.log(err);
    }
}

export const verifyOtp = async (value,source,data)=>{
    try{
    if(source==="phone")
    return await defaultAxios.post(otpVerify,{
        phone:data,
        otp:value
    });
    if(source==="email")
    return await defaultAxios.post(otpVerify,{
        email:data,
        otp:value
    })
   
    return Promise.reject("source should be email or phone");
    }
    catch(err){
        console.log(err);
    }
}


//question
export const questionPost = async (data)=>{
    try{
    return await adminAxios.post(postQuestion,data);
    }
    catch(err){
        console.log(err);
    }
}
export const questionDelete = async (id)=>{
    try{
        return await adminAxios.delete(deleteQuestion+"/"+id);
    }
    catch(err){
        console.log(err);
    }
}
export const questionGet = async (id)=>{
    try{
        // console.log(`${viewQuestion}/${id}`)
        return await adminAxios.get(`${viewQuestion}/${id}`);
    }
    catch(err){
        console.log(err);
    }
}
export const questionUpdate = async (data,id)=>{
    try{
        return await adminAxios.put(editQuestion+"/"+id,data);
    }
    catch(err){
        console.log(err);
    }
}



//question sequence
export const getQuestionSequence = async (type,id)=>{
    try{
        return await adminAxios.get(`${questionSequenceGet}/${type}?questionCategory=${id}`);
    }
    catch(err){
        console.log(err);
    }
}

export const updateQuestionSequence = async (questions,type)=>{
    try{
        if(!questions) return;
        const _ids= questions.map((el,idx)=>el._id) || [];
        return await adminAxios.put(questionSequenceUpdate+"/"+type,{
            questionSequence:_ids
        });
    }
    catch(err){
        console.log(err);
    }
}


//chart question
export const questionChartPost = async (data)=>{
    try{
    return await adminAxios.post(postChartQuestion,data);
    }
    catch(err){
        console.log(err);
    }
}
export const questionChartDelete = async (id)=>{
    try{
        return await adminAxios.delete(deleteChartQuestion+"/"+id);
    }
    catch(err){
        console.log(err);
    }
}
export const questionChartGet = async (id)=>{
    try{
        return await adminAxios.get(viewChartQuestion+"/"+id);
    }
    catch(err){
        console.log(err);
    }
}
export const questionChartUpdate = async (data,id)=>{
    try{
        return await adminAxios.put(editChartQuestion+"/"+id,data);
    }
    catch(err){
        console.log(err);
    }
}


//chart question sequence
export const getChartQuestionSequence = async (type)=>{
    try{
        return await adminAxios.get(questionChartSequenceGet+"/"+type);
    }
    catch(err){
        console.log(err);
    }
}

export const updateChartQuestionSequence = async (questions,type)=>{
    try{
        if(!questions) return;
        const _ids= questions.map((el,idx)=>el._id) || [];
        return await adminAxios.put(questionChartSequenceUpdate+"/"+type,{
            questionSequence:_ids
        });
    }
    catch(err){
        console.log(err);
    }
}


//chart question
export const membershipPost = async (data)=>{
    try{
    return await adminAxios.post(membershipPostRoute,data);
    }
    catch(err){
        console.log(err);
    }
}


export const membershipDelete = async (id)=>{
    try{
        return await adminAxios.delete(membershipDeleteRoute+"/"+id);
    }
    catch(err){
        console.log(err);
    }
}
export const membershipGet = async (id)=>{
    try{
        return await adminAxios.get(membershipGetRoute+"/"+id);
    }
    catch(err){
        console.log(err);
    }
}
export const membershipUpdate = async (data,id)=>{
    try{
        return await adminAxios.put(membershipUpdateRoute+"/"+id,data);
    }
    catch(err){
        console.log(err);
    }
}


//chart question sequence
export const getMembership = async (type)=>{
    try{
        // console.log(type);
        return await adminAxios.get(membershipGetRoute+"?type="+type);
    }
    catch(err){
        console.log(err);
    }
}

// export const updateMembership = async (questions,type)=>{
//     try{
//         if(!questions) return;
//         const _ids= questions.map((el,idx)=>el._id) || [];
//         return await adminAxios.put(membershipUpdateRoute+"/"+type,{
//             questionSequence:_ids
//         });
//     }
//     catch(err){
//         console.log(err);
//     }
// }





export const timeSlotPost = async (data)=>{
    try{
        return await adminAxios.post(postTimeSlot,data);
    }catch(err){
        console.log(err);
    }       
}
export const getTimeSlotsByType = async (type)=>{
    try{
        return await adminAxios.get(getTimeSlot+"?type="+type);
    }catch(err){
        console.log(err);
    } 
}

export const deleteTimeSlotById = async (id)=>{
    try{
        return await adminAxios.delete(deleteTimeSlot+"/"+id);
    }
    catch(err){
        console.log(err);
    }
}



export const fetchAllUsers = async (filterObj)=>{
    try{
        let filterQuery="?";
        // console.log(filterObj);
        Object.keys(filterObj).forEach((el)=>filterQuery=filterQuery+el+"="+filterObj[el]+"&");
        // console.log(filterQuery);

        return await adminAxios.get(fetchUsers+filterQuery);
    }
    catch(err){
        console.log(err);
    }
}

export const fetchUserById = async (id)=>{
    try{
        return await adminAxios.get(fetchUsers+"/"+id);
    }catch(err){
        console.log(err);
    }
}


export const fetchAppointmentById = async (id)=>{
    try{
        return await adminAxios.get(fetchAppointments+"/"+id);
    }catch(err){
        console.log(err);
    }
}

export const fetchAppointment = async (id)=>{
    try{
        return await adminAxios.get(fetchAppointments);
    }catch(err){
        console.log(err);
    }
}

export const fetchAnswersById = async (id)=>{
    try{
        return await adminAxios.get(fetchAnswers+"/"+id);
    }catch(err){
        console.log(err);
    }
}


export const fetchChartAnswersById = async (id)=>{
    try{
        return await adminAxios.get(fetchChartAnswers+"/"+id);
    }catch(err){
        console.log(err);
    }
}

export const fetchDietUsers = async ()=>{
    try{
        return await adminAxios.post(fetchChartUsers,{});
    }catch(err){
        console.log(err);
    }
}

export const submitDietChart = async (data,id)=>{
    try{
        return await adminAxios.post(addChart+"/"+id,data);
    }catch(err){
        console.log(err);
    }
}


export const getApprovalUsers = async ()=>{
    try{
        return await adminAxios.get(approvalUsers);
    }catch(err){
        console.log(err);
    }
}

export const approvePayment = async (id) =>{
    try{
        return await adminAxios.get(paymentApprove+"/"+id);
    }catch(err){
        console.log(err);
    }
}

export const getQuestionCategory = async ()=>{
    try{
        return await adminAxios.get(questionCategoryGet);
    }catch(err){
        console.log(err);
    }
}

export const getAllAttempt = async (type)=>{
    try{
        return await adminAxios.get(allAttempt);
    }catch(err){
        console.log(err);
    } 
}

export const getQuestionCat = async ()=>{
    try{
        console.log(questionCategoryGet);
        return await adminAxios.get(questionCategoryGet);
    }catch(err){
        console.log(err);
    }
}


export const couponPost = async (data)=>{
    try{
        return await adminAxios.post(postCoupon,data);
    }catch(err){
        console.log(err);
    }
}

export const couponDelete = async (code) =>{
    try{
        return await adminAxios.delete(deleteCoupon+"/"+code);
    }
    catch(err){
        console.log(err);
    }
}
 
export const couponEdit = async (code,data)=>{
    try{
        return await adminAxios.put(editCoupon+"/"+code,data);
    }catch(err){
        console.log(err);
    }
}


export const AddUser = async (data)=>{
    try{
    return await adminAxios.post(addUser,data);
    }
    catch(err){
        console.log(err);
    }
}