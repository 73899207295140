import styles from "./Box.module.css";

const Box = ({ image, text }) => {
    return (
        <div className={styles["container"]}>
            <div className={styles["content"]}>
                <div className={styles["icon"]}>
                    <img src={image} alt="Service Icon" style={{borderRadius:"10px"}}/>
                </div>
                <div className={styles["para"]}>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
};

export default Box;
