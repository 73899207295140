import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Switch from "../../../elements/Switch";
import styles from "./Table.module.css";
const TableHeader = (props)=>{
    return <thead class="thead-dark">
        <tr>
            {props.data && props.data.map((el,idx)=>{
                return <th style={{minWidth:"180px"}} scope="col">{el}</th>
            })}
        </tr>
    </thead>
}

const TableRow = (props)=>{
   
    // const callDelete = (e)=>{
    //     props.deleteById(props.data[0]);
    // }

    // const [isIndian,setIsIndian]=useState(props.data[4]);
    // const [isActive,setIsActive]=useState(props.data[5]);


    const deleteCouponHandler = (e)=>{
        props.deleteCouponByCode(props.data[1]);
    }

    const changeIsIndian = (state)=>{
        props.editCoupon(props.data[1],{
            forIndia:state
        });
    }

    const changeIsActive = (state)=>{
        props.editCoupon(props.data[1],{
            isActive:state
        });
    }


    
    return <tr>
        {props.data && <th scope="row">{props.data[0]}</th>}
        {
            props.data && props.data.map((el,idx)=>{
                if(idx==0) return <></>;

                // if(idx==props.data.length-1 && props.makeLastBtn) return <td><div className={styles["link"]} onClick={callDelete}>{el}</div></td>

                if(idx==4) return <td><div className={styles["tag"]}><Switch checked={el} changeState={changeIsIndian}/></div></td>
                if(idx==5) return <td><div className={styles["tag"]}><Switch checked={el} changeState={changeIsActive}/></div></td>
                if(idx==6) return <td><div className={styles["link"]} onClick={deleteCouponHandler}>{el}</div></td>

                return <td>{el}</td>
            })
        }
    </tr>
}
const Table=(props)=>{
    let rowData = props.data?props.data:[];
    if(rowData) rowData=rowData.length>1?rowData.slice(1):[];

    return <div className={styles["container"]}>
    <div className={styles["wrapper"]}>
    <div className={styles["content"]}>
       <table class="table table-light">
            <TableHeader data={props.data?props.data[0]:[]}/>
            <tbody>
                {
                    rowData && rowData.map((el,idx)=>{
                        return <TableRow data={el} deleteCouponByCode={props.deleteCouponByCode} editCoupon={props.editCoupon}/>
                    })
                }
            </tbody>
        </table>
    </div>
    </div>
    {/* <div className={styles["options"]}>
        <div className={styles["left"]}>Showing page 1 of 16 </div>
        <div className={styles["right"]}>
            <div>Prev</div>
            <div>Next</div>
        </div>
    </div> */}
    </div>
}
export default Table