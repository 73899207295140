import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from "./Services.module.css";

const Services = () => {
  const [services, setServices] = useState([]);

  const handleKnowMore = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/knowmore/all`
      );
      console.log("Know More Fetched successfully", response.data.data.knowMoreData);
      setServices(response.data.data.knowMoreData);
    } catch (error) {
      console.error("Error fetching details", error);
    }
  };

  useEffect(() => {
    handleKnowMore();
  }, []);

  return (
    <div className={styles["container"]}>
      <h2 className={styles["heading"]}>Know More</h2>
      {services.map((service, index) => (
        <div
          key={service._id}
          className={`${styles["content"]} ${index % 2 === 0 ? styles["content__odd"] : styles["content__even"]}`}
        >
          {index % 2 === 0 ? (
            <>
              <div className={styles["img"]}>
                <img src={service.mediaLink} alt={service.subTitle} style={{width:"200px"}}/>
              </div>
              <div className={styles["details"]}>
                <h4>{service.title}</h4>
                <p>{service.body}</p>
              </div>
            </>
          ) : (
            <>
              <div className={styles["details"]}>
                <h4>{service.title}</h4>
                <p>{service.body}</p>
              </div>
              <div className={styles["img"]}>
                <img src={service.mediaLink} alt={service.subTitle} style={{width:"200px"}}/>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default Services;
