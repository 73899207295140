import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
    someInitHandler
} from "./Init.thunk";

const INIT_STATE = {
  someInitState:""
};

export const someInitHandlerAsyncThunk = createAsyncThunk(
  "someInitHandler",
  someInitHandler
);

const initSlice = createSlice({
    name: "init",
    initialState: INIT_STATE,
    reducers: {
      setInit: (state, action) => {
        state.someInitState = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(someInitHandlerAsyncThunk.fulfilled, (state) => {
          state.someInitState = "sjhsj";
          return state;
        })
        .addCase(someInitHandlerAsyncThunk.rejected, (state) => {
          state.someInitState = "sbhsh";
          return state;
        });
    },
  });
  
  export const { setInit } = initSlice.actions;
  
  export default initSlice.reducer;