//Custom module imports
import { useEffect, useState } from "react";
import Blog from "../../sections/Blog/Blog";
import Boxes from "../../sections/Boxes";
import Data from "../../sections/Data/Data";
import Diseases from "../../sections/Diseases";
import Download from "../../sections/Download";
import FAQList from "../../sections/FAQ/FAQList";
import Footer from "../../sections/Footer";
import FreeConsultation from "../../sections/FreeConsultation/FreeConsultation";
import FullImage from "../../sections/FullImage";
import Header from "../../sections/Header";
import Hero from "../../sections/Hero";
import Inspiration from "../../sections/Inspiration";
import Referral from "../../sections/Referral/Referral";
import Services from "../../sections/Services";
import SliderContainer from "../../sections/SliderContainer";
import Testimonials from "../../sections/Testimonials";
import YoutubeSection from "../../sections/YoutubeSection";
import styles from "./Home.module.css";
import axios from "axios";

const Home = (props) => {
  const [allData, setAllData] = useState([]);
  const [allLeftData, setAllLeftData] = useState([]);

  let slider1 = [
    {
      id: 1,
      src: require("../../../assets/slider1/slider1.jpeg"),
    },
    {
      id: 2,
      src: require("../../../assets/slider1/slider2.jpeg"),
    },
    {
      id: 3,
      src: require("../../../assets/slider1/slider3.jpeg"),
    },
    {
      id: 4,
      src: require("../../../assets/slider1/slider4.jpeg"),
    },
    {
      id: 5,
      src: require("../../../assets/slider1/slider5.jpeg"),
    },
  ];

  const handleMedia = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/media-center`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Media Center Fetched successfully", response.data.data);
      setAllData(response.data.data);
      let leftData = [...response.data.data];
      if (response.data.data.length > 5) {
        leftData = leftData.splice(5, response.data.data.length);
        setAllLeftData(leftData);
      }
    } catch (error) {
      console.log("Error fetching Media Center", error);
    }
  };
  useEffect(() => {
    handleMedia();
  }, []);

  return (
    <div class={styles["container"]}>
      <Header />
      <Hero />
      <Download />
      <Services />
      {allData[0] &&
        (allData[0]?.imageType === "image" ? (
          <FullImage imgUrl={allData[0]?.imageUrl} />
        ) : (
          <YoutubeSection url={allData[0]?.imageUrl} />
        ))}
      <Boxes />
      {allData[1] &&
        (allData[1]?.imageType === "image" ? (
          <FullImage imgUrl={allData[1]?.imageUrl} />
        ) : (
          <YoutubeSection url={allData[1]?.imageUrl} />
        ))}
      <Diseases />
      {/* <SliderContainer images={slider1} /> */}
      {allData[2] &&
        (allData[2]?.imageType === "image" ? (
          <FullImage imgUrl={allData[2]?.imageUrl} />
        ) : (
          <YoutubeSection url={allData[2]?.imageUrl} />
        ))}
      {allData[3] &&
        (allData[3]?.imageType === "image" ? (
          <FullImage imgUrl={allData[3]?.imageUrl} />
        ) : (
          <YoutubeSection url={allData[3]?.imageUrl} />
        ))}
      {/* <Referral/> */}
      {/* <FreeConsultation /> */}
      <Data />
      {allData[4] &&
        (allData[4]?.imageType === "image" ? (
          <FullImage imgUrl={allData[4]?.imageUrl} />
        ) : (
          <YoutubeSection url={allData[4]?.imageUrl} />
        ))}
      {/* <Testimonials/> */}
      <Inspiration />
      {allLeftData.length >= 1 ? (
        allLeftData.map((el, index) =>
          el?.imageType === "image" ? (
            <FullImage key={index} imgUrl={el?.imageUrl} />
          ) : (
            <YoutubeSection key={index} url={el?.imageUrl} />
          )
        )
      ) : (
        <></>
      )}
      <Blog />
      <FAQList />
      <Footer />
    </div>
  );
};
export default Home;
