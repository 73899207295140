export const adminLogin ="/send-otp";
export const otpVerify ="/verify-otp";

export const postQuestion="/add-question";
export const deleteQuestion="/delete-question";
export const viewQuestion="/view-question";
export const editQuestion="/edit-question";


export const questionSequenceGet = "/view-sequence";
export const questionSequenceUpdate = "/update-sequence";

export const postChartQuestion="/add-diet-question";
export const deleteChartQuestion="/delete-diet-question";
export const viewChartQuestion="/view-diet-question";
export const editChartQuestion="/edit-diet-question";


export const questionChartSequenceGet = "/view-diet-sequence";
export const questionChartSequenceUpdate = "/update-diet-sequence";


export const membershipGetRoute="/view-membership";
export const membershipPostRoute="/add-membership";
export const membershipDeleteRoute="/delete-membership";
export const membershipUpdateRoute="/edit-membership";

export const postTimeSlot="/add-slot";
export const getTimeSlot="/view-slot";
export const deleteTimeSlot="/delete-slot";

export const fetchUsers = "/view-user";

export const fetchAppointments = "/view-appointment";

export const fetchAnswers = "/view-attempt";

export const fetchChartAnswers = "/view-diet-attempt";

export const fetchChartUsers = "/view-diet-user";
export const addChart = "/add-diet-attempt";

export const approvalUsers = "/withdrawal-users";
export const paymentApprove = "/withdrawal-done";

export const questionCategoryGet = "/view-category";

export const postCoupon = "/create-coupon";
export const deleteCoupon = "/delete-coupon";
export const editCoupon = "/edit-coupon";

export const uplaodQuestion = "/upload-question-image"
export const editMembership = "/edit-membership"

export const addUser = "/add-user/website"

export const allAttempt = "/get-attempt"


