import { formatQuestions } from "../../utils/generic";
import { getChartQuestionSequence, questionChartDelete, updateChartQuestionSequence } from "../../utils/global.apis";

export const fetchChartQuestionSequenceByTypeHandler = async (__, thunkApi) => {
    try {
      const res = await getChartQuestionSequence(__);
      // console.log(res);
      return formatQuestions(res.data.data.questionSequence,__);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const updateChartQuestionSequenceByTypeHandler = async (__, thunkApi) => {
    try {
      const res = await updateChartQuestionSequence(__.questions,__.type);

      return formatQuestions(res.data.data.questionSequence,__);

    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const deleteChartQuestionFromSequenceByIdHandler = async (__, thunkApi) => {
    try {
      const res = await questionChartDelete(__);
      return __;
    //   return formatQuestions(res.data.data.questionSequence,__);
    } catch (err) {
      return Promise.reject(err);
    }
  };