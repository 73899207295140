import React, { useState, useEffect, useCallback } from "react";
import { color } from "./Color";
import axios from "axios";
import endUrls from "./ApiUrl";
import { getToken, getType } from "./AsyncStorage";
import Questions from "./Questions";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getQuestionCategory, getQuestionData } from "./service";
import { FaChevronLeft } from "react-icons/fa";
import Header from "../../../sections/Header";
import Footer from "../../../sections/Footer";
import ClipLoader from "react-spinners/ClipLoader";
import { defaultAxios } from "../../../../utils/axios/default.axios";

const Form = () => {
  const [questionData, setQuestionData] = useState(null);
  const [questionCategory, setQuestionCategory] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [doneCategory, setDoneCategory] = useState([]);
  const [progress, setProgress] = useState(0);
  const [type, setType] = useState(null);
  const [reRender, setReRender] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [questionDataServer, setQuestionDataServer] = useState(null); // Add loading state
  const navigate = useNavigate();
  const { data: questionCategoryData,refetch } = useQuery({
    queryKey: ["questionCategory"],
    queryFn: () => getQuestionCategory(),
  });
  // const { data: questionDataServer } = useQuery({
  //   queryKey: ["questionDataServer", questionCategory, selectedIndex],
  //   queryFn: () => getQuestionData(questionCategory[selectedIndex]?._id),
  // });
  const getQuestions = async () => {
    const type = await getType();
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${endUrls.baseUrl}${endUrls.questionnaire}/${type}?questionCategory=${questionCategory[selectedIndex]?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setQuestionDataServer(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (questionCategory[selectedIndex]?._id) {
      getQuestions();
    }
  }, [questionCategory[selectedIndex]?._id]);

  useEffect(() => {
    setType(localStorage.getItem("type"));
  }, [type]);
  const routes = useParams();
  useEffect(() => {
    let user_id = localStorage.getItem("user_id");
    setSelectedUser(user_id);
  }, []);

  useEffect(() => {
    if (questionCategoryData) {
      if (localStorage.getItem("gender") === "male") {
        let category = questionCategoryData?.data?.data?.filter(
          (ele) => ele.name !== "Women"
        );
        setQuestionCategory(category);
        return;
      }
      else {
        setQuestionCategory(questionCategoryData?.data?.data);
      }
      setLoading(false);
    }
  }, [questionCategoryData]);

  useEffect(() => {
    if (questionDataServer) {
      let data = questionDataServer?.data?.data?.questionSequence;
      data.forEach((element) => {
        if (element.type === "bool") {
          element["options"] = [{ text: "Yes" }, { text: "No" }];
          element["answer"] = "";
        } else if (element.type === "checkbox") {
          element.tempOptions = [];
          element.tempHindiOptions = [];
          element.options.forEach((e) => {
            element.tempOptions.push({
              text: e,
              checked: false,
            });
          });
          element.hindiOptions.forEach((e) => {
            element.tempHindiOptions.push({
              text: e,
              checked: false,
            });
          });
          element["answer"] = "";
        } else {
          element["answer"] = "";
        }
      });
      setQuestionData([...data]);
      setLoading(false);
    }
  }, [questionDataServer]);

  useEffect(() => {
    if (selectedUser) {
      getAnswerAttempt();
    }
  }, [questionCategory.length]);

  const getAnswerAttempt = async () => {
    if (!selectedUser) {
      return "";
    }

    try {
      const token = await getToken();
      const type = await getType();

      const response = await axios.get(
        `${endUrls.baseUrl}${endUrls.viewAttempt}/${selectedUser}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDoneCategory(response.data.data.category);
      getLeftCategory(response.data.data.category);
    } catch (error) {
      if (error?.response?.status === 400) {
        setSelectedIndex(0);
        setProgress(0);
      } else {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
        alert("Answer already exists for this user!");
      }
    }
  };

  const getLeftCategory = (category) => {
    var categoryList = [];
    questionCategory.forEach((element, index) => {
      let check = 0;
      category.forEach((ele) => {
        if (element.name === ele) {
          check = 1;
        }
      });
      if (check === 0) {
        categoryList.push(index);
      }
    });
    const progress =
      (questionCategory.length - categoryList.length) / questionCategory.length;
    if (progress === 1) {
      navigate("/register");
    }
    setProgress(progress);
    setSelectedIndex(categoryList[0]);
  };

  const handleSubmit = async () => {
    setLoading(true)
    const answer = [];
    let exitLoop = false;

    try {
      questionData.every((item) => {
        if (item.type === "checkbox") {
          let ans = [];
          const options =
            type === "I-H" ? item.tempHindiOptions : item.tempOptions;

          options.forEach((i) => {
            if (i.checked) {
              if (i.text === (type === "I-H" ? "अन्य" : "Other")) {
                if (item.answer.length > 0) {
                  ans.push(item.answer);
                }
              } else {
                ans.push(i.text);
              }
            }
          });

          if (ans.length === 0) {
            alert("All questions are mandatory");
            setLoading(false)
            exitLoop = true;
            return false;
          }

          answer.push({
            question: item._id,
            answer: ans,
          });
        } else {
          if (item?.answer.length === 0) {
            alert("All questions are mandatory");
            setLoading(false)
            exitLoop = true;
            return false;
          }

          answer.push({
            question: item._id,
            answer: [item?.answer],
          });
        }

        return true;
      });

      if (!exitLoop) {
        await submitData(answer);
      }
      
    } catch (err) {
      console.error("Error:", err);
      alert("An error occurred. Please try again.");
    } finally {
      window.scrollTo(0, 0);
    }
  };

  const submitData = async (answer) => {
    
    try {
      const token = await getToken();
      const a = {
        answers: answer,
        category: questionCategory[selectedIndex].name,
        isAnswerCompleted:
          selectedIndex === questionCategory.length - 1 ? true : false,
      };
      const response = await axios.post(
        `${endUrls.baseUrl}${endUrls.addAttempt}/${selectedUser}`,
        a,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (selectedIndex === questionCategory.length - 1) {
        alert("Form submitted successfully");
        navigate("/");
      } else {
        alert("Form saved successfully");
        setQuestionData([]);
        setQuestionCategory([]);
        setSelectedIndex(-1);
        setDoneCategory([]);
        getQuestionCategory();
        refetch();
      }
    } catch (err) {
      console.error("Error:", err);
      if (err.response) {
        console.error("Response Error:", err.response);
      }
      alert("Error occurred, please try again");
    } finally {
      setLoading(false);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Header />
      <div className="container p-4">
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 5,
            marginTop: 90,
          }}
          className="d-flex fw-semibold gap-2"
        >
          <div
            style={{
              fontSize: 25,
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaChevronLeft onClick={() => window.history.go(-1)} />
            Questionnaire!
          </div>
        </div>
        {type === "I-E" || type === "OI" ? (
          <div
            style={{
              color: color.Black,
              fontSize: 22,
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            Category: {questionCategory[selectedIndex]?.name}
          </div>
        ) : (
          <div
            style={{
              color: color.Black,
              fontSize: 20,
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            वर्ग: {questionCategory[selectedIndex]?.hindiName}
          </div>
        )}
        <div style={{ width: "90%", alignSelf: "center", marginBottom: 10 }}>
          <ProgressBar
            completed={isNaN(progress * 100) ? 0 : Math.round(progress * 100)}
          />
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <ClipLoader
              color={"rgb(118,0,159)"}
              loading={loading}
              size={80}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            {/* Debugging message */}
            {/* <p>Loading...</p> */}
          </div>
        ) : (
          questionData?.map((item, index) => (
            <Questions
              key={index}
              item={item}
              index={index}
              type={type}
              questionData={questionData}
              setQuestionData={setQuestionData}
              reRender={reRender}
              setReRender={setReRender}
            />
          ))
        )}

        <button
          style={{ marginTop: 20 }}
          className="btn btn-secondary w-100"
          onClick={() => {
            handleSubmit();
          }}
        >
          {selectedIndex === questionCategory.length - 1
            ? "Submit Form"
            : "Save & Next"}
        </button>
      </div>
      <Footer />
    </>
  );
};

export default Form;

