import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
    deleteChartQuestionFromSequenceByIdHandler,
    fetchChartQuestionSequenceByTypeHandler, updateChartQuestionSequenceByTypeHandler
} from "./ChartQuestionSequence.thunk";

const INIT_STATE = {
  chartQuestionSequence:[]
};

export const fetchChartQuestionSequenceByType = createAsyncThunk(
  "fetchChartQuestionSequenceByTypeHandler",
  fetchChartQuestionSequenceByTypeHandler
);

export const updateChartQuestionSequenceByType = createAsyncThunk(
    "updateChartQuestionSequenceByTypeHandler",
    updateChartQuestionSequenceByTypeHandler
);

export const deleteChartQuestionFromSequenceById = createAsyncThunk(
    "deleteChartQuestioFromnSequenceByIdHandler",
    deleteChartQuestionFromSequenceByIdHandler
);

const chartQuestionSequenceSlice = createSlice({
    name: "chartQuestionSequence",
    initialState: INIT_STATE,
    reducers: {
      setChartQuestionSequence: (state, action) => {
        state.chartQuestionSequence = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchChartQuestionSequenceByType.fulfilled, (state, payload) => {
          state.chartQuestionSequence = payload.payload;
          return state;
        })
        .addCase(fetchChartQuestionSequenceByType.rejected, (state) => {
          return state;
        })
        .addCase(updateChartQuestionSequenceByType.fulfilled, (state, payload) => {
            state.chartQuestionSequence = payload.payload;
            return state;
        })
        .addCase(updateChartQuestionSequenceByType.rejected, (state) => {
            return state;
        })
        .addCase(deleteChartQuestionFromSequenceById.fulfilled, (state, payload) => {
            let idx=[...state.chartQuestionSequence].findIndex(el=>el._id==payload.payload);
            if(idx==-1) return state;
            let arr= [...state.chartQuestionSequence];
            arr.splice(idx,1);
            state.chartQuestionSequence=arr;
            return state;
        })
        .addCase(deleteChartQuestionFromSequenceById.rejected, (state) => {
            return state;
        });
    },
  });
  
  export const { setChartQuestionSequence } = chartQuestionSequenceSlice.actions;
  
  export default chartQuestionSequenceSlice.reducer;