import styles from "./uploadimage.module.css";
import {AiFillCloseCircle} from "react-icons/ai";
import { useState } from "react";
import fileIcon from "../../../../assets/file.png"
import removeFile from "../../../../assets/dustbin.png"
import { editMembership, uplaodQuestion } from "../../../../utils/apis.utils";
import { adminAxios } from "../../../../utils/axios/admin.axios";
import axios from "axios";


const UploadMembershipImage = (props)=>{

    const [data,setData] = useState()
    const clickFile = () => {
        const file = document.querySelector("#fileInput")
        file.click();
    }
    const seeFile = (e) => {
        // console.log(e.target.files[0]);
        setData(e.target.files[0])
    }
    const dragEnter = (e) => {
        e.preventDefault();
    }
    const dragLeave = (e) => {
        e.preventDefault();
    }
    const dragOver = (e) => {
        e.preventDefault();
    }
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if(files[0].type!=="image/*")
        {   
            alert("not a image file")
        }else{
            console.log(files[0]);
            setData(files[0])
        }
    }

    const uploadFile = async() => {
        if(!data) return
        let token = localStorage.getItem('jwt');
        const form = new FormData()
        form.append("file",data)
        console.log(form)
            axios.put(`${process.env.REACT_APP_BASE_URL}${editMembership}/${props.id}`,form,
            {
                headers:{
                    "Content-Type":"multipart/form-data",
                "Authorization": `Bearer ${token}`
                }

            }).then((res)=>{
                alert("Uploaded successfully!")
                props.onClose()
                console.log(res)
            }).catch((err)=>{
                console.log(err)
            })
    }

    //Markup
    return <div className={styles["file-box"]}>
        <div className={styles["close"]} onClick={props.onClose}><AiFillCloseCircle/></div>
                <div className={styles["file-upload-container"]}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                >
                    <h2>Upload Your Image</h2>
                    <p>File should be a image</p>
                    {
                        data?
                        <div className={styles["file-upload-box"]} style={{cursor: "default"}}>
                            <img src={fileIcon}/>
                            <div className={styles["uploaded-file-name"]}>
                            <h3 style={{textAlign:"center"}}>{data.name}</h3>
                            <img src={removeFile} onClick={()=>setData()}/>
                            </div>
                            </div>
                        :
                        <div className={styles["file-upload-box"]}
                        onClick={
                            () => {
                                clickFile()
                            }
                    }>
                        <input type="file" id="fileInput" className={styles["file-input"]} accept='image/*'
                            onChange={
                                (e) => {
                                    seeFile(e)
                                }
                            }/>
                        <img src={fileIcon}/>
                        <p>Drag a file or click here</p>
                    </div>
                    }
                    <div className={styles["btn"]} onClick={()=>{uploadFile()}}>Upload Button</div>
                </div>
    </div>;
}
export default UploadMembershipImage;


{/* <div className={styles["inner"]}>
            <h3>Hindi</h3>
                <div className={styles["add_question"]}>
                    <div className={styles["aq_text"]}>Add Your Question</div>
                    <div className={styles["aq_inp"]}>
                        <textarea/>
                    </div>
                </div>
                {showOptions && <div className={styles["options"]}>
                    <div className={styles["o_text"]}>Options</div>
                    <div className={styles["o_sub-text"]}>
                        <div className={styles["o_sub-text--content"]} onClick={addOptionHandler}>Add Option</div>
                    </div>
                    {
                        Array(noOfOptions).fill(0).map((el,idx)=>(
                            <div className={styles["option"]}>
                                <Option idx={idx+1} onClose={closeOptionHandler}/>
                            </div>
                        ))
                    }
                    
                    
                </div>}
            </div> */}

            {/* <div className={styles["option"]}>
                        <Option idx={2}/>
                    </div> */}