import styles from "./SideNav.module.css";
import logo from "./../../../assets/logo.png";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const SideNav = (props)=>{
    const [searchParams, setSearchParams] = useSearchParams();
    // const [activeIdx,setActiveIdx] = useState(searchParams.get('activeIdx')?searchParams.get('activeIdx'):0);

    // const navData=[
    //     {
    //         title:"Questionare management"
    //     }
    // ];

    useEffect(()=>{
        setSearchParams({activeIdx:props.activeIdx});
    },[props.activeIdx]);

    
    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <div className={styles["brand"]}>
                <img src={logo} alt="logo"/>
                <h2>Healin Roots</h2>
            </div>
            <div className={styles["nav"]}>
                {/* <div className={`${styles["items"]} ${styles["active"]}`}>Nav 1</div>
                <div className={styles["items"]}>Nav 2</div>
                <div className={styles["items"]}>Nav 3</div> */}
                {
                    props.navData.map((el,idx)=>{
                        return <div className={idx==props.activeIdx?`${styles["items"]} ${styles["active"]}`:styles["items"]} onClick={(e)=>props.setActiveIdx(idx)}>{el.title}</div>;
                    })
                }
            </div>
        </div>
    </div>
}

export default SideNav;