import React, { useEffect, useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import styles from "./Register.module.css";
import axios from 'axios';
import endUrls from '../Test/ApiUrl';
import { useNavigate } from 'react-router-dom';
import { setItem } from '../Test/AsyncStorage';

function MemberModal({ isOpen, onOpen, onOpenChange }) {
    const [userList, setUserList] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [newUserAdded, setNewUserAdded] = useState(false);
    const [dob, setDob] = useState(null);
    const [gender, setGender] = useState("male");
    const [name, setName] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUser();
    }, [isOpen, newUserAdded]);

    const fetchUser = () => {
        axios.get(`${endUrls.baseUrl}${endUrls.userById}/${localStorage.getItem("user_id")}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((item) => {
            const userList = [
                item.data.data,
                ...item.data.data.userMember
            ];
            setUserList(userList);
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleSubmit = (el) => {
        setSelectedUser(el);
        setIsLanguageModalOpen(true);
    };

    const handleLanguageSelection = (language) => {
        const el = selectedUser;
        axios.get(`${endUrls.baseUrl}${endUrls.viewAttempt}/${el._id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((item) => {
            if (item.data.data.isAnswerCompleted) {
                alert("Answers are already filled");
            } else {
                setItem('user_id', el._id);
                setItem('type', language === 'English' ? "I-E" : "I-H");
                setItem('gender', el.gender);
                navigate("/form");
            }
        }).catch((error) => {
            if (error?.response?.status == 400) {
                setItem('user_id', el._id);
                setItem('type', language === 'English' ? "I-E" : "I-H");
                setItem('gender', el.gender);
                navigate("/form");
            } else {
                alert("Answer already exists for this user!");
            }
        }).finally(() => {
            setIsLanguageModalOpen(false);
        });
    };

    const handleForm = (e) => {
        e.preventDefault();
        const body = {
            name: name,
            dateOfBirth: dob,
            gender: gender
        };
        axios.post(`${endUrls.baseUrl}${endUrls.addMember}/${localStorage.getItem("user_id")}`, body, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((res) => {
            setName(null);
            setDob(null);
            setNewUserAdded(!newUserAdded);
            setIsAdd(!isAdd);
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} style={{ zIndex: "99999", height: "500px" }} scrollBehavior={"inside"}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            {isAdd ? (
                                <>
                                    <ModalHeader className="flex flex-col gap-1">Add Member</ModalHeader>
                                    <ModalBody style={{ height: "400px" }}>
                                        <form onSubmit={(e) => handleForm(e)}>
                                            <div className="mb-4">
                                                <label className="form-label d-block ms-1">Gender</label>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="genderOptions" id="maleGender" value="male" checked={gender === "male"} onChange={(e) => setGender(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="maleGender">Male</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="genderOptions" id="femaleGender" value="female" checked={gender === "female"} onChange={(e) => setGender(e.target.value)} />
                                                    <label className="form-check-label" htmlFor="femaleGender">Female</label>
                                                </div>
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor="exampleFormControlInput1" className="form-label ms-1">Name</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor="exampleFormControlInput4" className="form-label ms-1">DOB</label>
                                                <input type="date" className="form-control" id="exampleFormControlInput4" value={dob} onChange={(e) => setDob(e.target.value)} required />
                                            </div>
                                            <div className='w-100 text-center'>
                                                <button className='btn btn-secondary w-auto' type='submit'>Submit</button>
                                            </div>
                                        </form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="danger" variant="light" onPress={onClose}>Close</Button>
                                        <Button color="primary" variant="light" onPress={() => setIsAdd(false)}>Select Member</Button>
                                    </ModalFooter>
                                </>
                            ) : (
                                <>
                                    <ModalHeader className="flex flex-col gap-1" style={{padding:"12px 24px"}}>Select Member
                                        <br></br>
                                        <span style={{
                                            fontSize:"12px",
                                            fontWeight:"normal"
                                        }}>(Click on your name, or add member by clicking add member)</span>
                                    </ModalHeader>
                                    <ModalBody style={{ height: "200px" }}>
                                        <p className={styles["member-line"]} onClick={() => setIsAdd(true)}>+ Add Member</p>
                                        {userList.map(el => (
                                            <p className={styles["member-line"]} onClick={() => handleSubmit(el)} key={el._id}>{el.name}</p>
                                        ))}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="danger" variant="light" onPress={onClose}>Close</Button>
                                    </ModalFooter>
                                </>
                            )}
                        </>
                    )}
                </ModalContent>
            </Modal>

            {/* Language Selection Modal */}
            <Modal isOpen={isLanguageModalOpen} onOpenChange={() => setIsLanguageModalOpen(!isLanguageModalOpen)}>
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">Select Language</ModalHeader>
                    <ModalBody style={{ height: "200px" }}>
                        <p className={styles["member-line"]} onClick={() => handleLanguageSelection('English')}>English</p>
                        <p className={styles["member-line"]} onClick={() => handleLanguageSelection('Hindi')}>Hindi</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={() => setIsLanguageModalOpen(false)}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default MemberModal;
