import { combineReducers } from "@reduxjs/toolkit";
import initReducer from "./Init/Init.slice";
import questionSequenceReducer from "./QuestionSequence/QuestionSequence.slice";
import chartQuestionSequenceReducer from "./ChartQuestionSequence/ChartQuestionSequence.slice";
import membershipReducer from "./Memberships/Membership.slice";




const rootReducer = combineReducers({
  init: initReducer,
  questionSequence:  questionSequenceReducer,
  chartSequence: chartQuestionSequenceReducer,
  membership: membershipReducer
});

export default rootReducer;