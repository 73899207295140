import styles from "./Form.module.css";
import { AiFillCloseCircle, AiOutlineCloseCircle } from "react-icons/ai";
import Option from "../Option";
import { useState } from "react";
import Content from "./Content";
import Select from "../../../elements/Select";
import CategoryData from "./../Category.data";
import AnswerTypeData from "./../AnswerType.data";
import { getQuestionCategory, questionGet, questionPost, questionUpdate } from "../../../../utils/global.apis";
import { useEffect } from "react";


const Form = (props) => {

    //STATES
    //to decide to show or not show options acc to input type
    const [showOptions, setShowOptions] = useState(false);

    //To save state
    const [answerType, setAnswerType] = useState('Text');
    const [subType, setSubType] = useState('text');
    const [categoryType, setCategoryType] = useState('Indian');
    const [question, setQuestion] = useState("");
    const [options, setOptions] = useState([""]);
    const [hindiOptions, setHindiOptions] = useState([""]);
    const [hindiQuestion, setHindiQuestion] = useState("");
    const [id, setId] = useState(null);
    const [typeId, setTypeId] = useState(null);

    //Question category i.e diabetes etc
    const [qCategoryAll, setQCategoryAll] = useState([]);
    const [qCategory, setQCategory] = useState([]);
    const [qCategoryValue, setQCategoryValue] = useState("");

    // useEffect(()=>{
    //     console.log("options",options);
    // },[options]);

    useEffect(() => {
        if (!typeId) return;
        let qCatVal = qCategoryAll.find(el => { console.log(el);return el._id == typeId });

        if (!qCatVal) setQCategoryValue(qCategoryAll[0]?.name);
        console.log("qCatVal",qCatVal)
        setQCategoryValue(qCatVal?.name);
    }, [typeId,qCategoryAll,qCategoryAll.length]);

    //To pre load fields in case of edit
    useEffect(() => {
        getQuestionCategory()
            .then(res => {
                // console.log(res.data.data);
                if (!res.data.data) return;
                let vals = res.data.data.map(el => el.name);
                setQCategory(vals);
                setQCategoryAll(res.data.data);
            })
            .catch(err => {
                console.log(err)
            })

        if (props.mode != "edit") return;

        questionGet(props.id)
            .then(res => {
                let data = res.data.data;
                setId(data._id);
                setTypeId(data.questionCategory);

                console.log("data",data)

                let qtype;
                if (data.type == "input") qtype = "Text";
                if (data.type == "radio") qtype = "Radio";
                if (data.type == "checkbox") qtype = "Checkbox";
                if (data.type == "bool") qtype = "Yes / No";
                setAnswerType(qtype);
                answerTypeChangeHandler({ target: { value: qtype } });

                let category;
                if (data.category == "I") category = "Indian";
                if (data.category == "OI") category = "Non-Indian";
                if (data.category == "B") category = "Both";
                setCategoryType(category);

                // console.log(data);
                setOptions(data.options ? data.options : [""]);
                setHindiOptions(data.hindiOptions ? data.hindiOptions : [""]);

                setQuestion(data.question);
                setHindiQuestion(data.hindiQuestion);
            })
            .catch(err => console.log(err));
    }, []);

    //On answer i.e input type change
    const answerTypeChangeHandler = (e) => {
        //set state
        setAnswerType(e.target.value);

        //Decide to show or not show options acc to state
        if (e.target.value != "Text" && e.target.value != "Yes / No") {
            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }
    }

    const subTypeChangeHandler = (e) => {
        //set state
        setSubType(e.target.value);
    }

    //to handle category type change
    const categoryTypeChangeHandler = (e) => {
        setCategoryType(e.target.value);
    }

    const qCategoryTypeChangeHandler = (e) => {
        console.log(e)
        setQCategoryValue(e.target.value);
    }
    //When clicked on CTA i.e BTN
    const postQuestionHandler = async (e) => {
        try {

            //Translate local variables acc to API body
            let category;
            if (categoryType == "Indian") category = "I";
            if (categoryType == "Non-Indian") category = "OI";
            if (categoryType == "Both") category = "B";


            let type;
            if (answerType == "Text") type = "input";
            if (answerType == "Radio") type = "radio";
            if (answerType == "Checkbox") type = "checkbox";
            if (answerType == "Yes / No") type = "bool";

            let flag = false;
            if (answerType == "Text" || answerType == "Yes / No") flag = true;
            options.map(el => { if (el !== "" && question != "") flag = true; return []; });
            hindiOptions.map(el => { if (el !== "" && hindiQuestion != "") flag = true; return []; });

            if (!flag) throw "Atleast 1 option need to be filled";
            let questionCategory = qCategoryAll.find(el => el.name == qCategoryValue)._id;

            if (!questionCategory) return alert("Question category required");

            const data = {
                category,
                type,
                question,
                hindiQuestion,
                options,
                hindiOptions,
                questionCategory
            };

            if(type=="input")
            {
                data["subType"]=subType
            }
            //Decide is it update or create
            let res;
            if (props.mode != "edit")
                res = await questionPost(data);
            else
                res = await questionUpdate(data, id);

            if (!res) throw "Something went wrong!";
            // console.log("still");
            //fetch entire questionare
            if (props.refresh) props.refresh();

            //close form popup
            props.onClose();
            if (props.mode != "edit")
                alert("Question added succesfully!");
            else
                alert("Question updated succesfully!");


        } catch (err) {
            alert(err);
            console.log(err);
        }
    }


    //When clicked on close option button
    const removeOption = (idx) => {
        if (!options || options.length <= 1) return;
        setOptions(state => {
            let arr = [...state];
            arr.splice(idx - 1, 1);
            return [...arr];
        });
    }
    const removeHindiOption = (idx) => {
        if (!hindiOptions || hindiOptions.length <= 1) return;
        setHindiOptions(state => {
            let arr = [...state];
            arr.splice(idx - 1, 1);
            return [...arr];
        });
    }

    //Markup
    return <div className={styles["container"]}>
        <div className={styles["close"]} onClick={props.onClose}><AiFillCloseCircle /></div>
        <div className={styles["heading"]}>{props.mode != "edit" ? "Add a question" : "Edit a question"}</div>
        <div className={styles["select_answer"]}>
            <div className={styles["sa_text"]}>Select Question Category</div>
            <div className={styles["sa_inp"]}>
                <Select options={qCategory} onChange={qCategoryTypeChangeHandler} init={qCategoryValue} />
            </div>
        </div>
        <div className={styles["select_answer"]}>
            <div className={styles["sa_text"]}>Select Category</div>
            <div className={styles["sa_inp"]}>
                <Select options={['Indian', 'Non-Indian', 'Both']} onChange={categoryTypeChangeHandler} init={categoryType} />
            </div>
        </div>
        <div className={styles["select_answer"]}>
            <div className={styles["sa_text"]}>Select Answer Type</div>
            <div className={styles["sa_inp"]}>
                <Select options={['Text', 'Radio', 'Checkbox', 'Yes / No']} onChange={answerTypeChangeHandler} init={answerType} />
            </div>
        </div>
        {
            answerType == "Text" ?
                <div className={styles["select_answer"]}>
                    <div className={styles["sa_text"]}>Select Sub Type</div>
                    <div className={styles["sa_inp"]}>
                        <Select options={['text', 'number']} onChange={subTypeChangeHandler} init={subType} />
                    </div>
                </div>
                :
                <></>
        }
        <div className={styles["content"]}>
            <Content language="English" showOptions={showOptions} setQuestion={setQuestion} question={question} setOptions={setOptions} removeOption={removeOption} options={options} />
        </div>
        {categoryType != "Non-Indian" && <div className={styles["content"]}>
            <Content language="Hindi" showOptions={showOptions} setQuestion={setHindiQuestion} question={hindiQuestion} setOptions={setHindiOptions} removeOption={removeHindiOption} options={hindiOptions} />
        </div>}
        <div className={styles["cta"]}>
            <div className={styles["btn"]} onClick={postQuestionHandler}>{props.mode != "edit" ? "Submit" : "Save"}</div>
        </div>
    </div>;
}
export default Form;


{/* <div className={styles["inner"]}>
            <h3>Hindi</h3>
                <div className={styles["add_question"]}>
                    <div className={styles["aq_text"]}>Add Your Question</div>
                    <div className={styles["aq_inp"]}>
                        <textarea/>
                    </div>
                </div>
                {showOptions && <div className={styles["options"]}>
                    <div className={styles["o_text"]}>Options</div>
                    <div className={styles["o_sub-text"]}>
                        <div className={styles["o_sub-text--content"]} onClick={addOptionHandler}>Add Option</div>
                    </div>
                    {
                        Array(noOfOptions).fill(0).map((el,idx)=>(
                            <div className={styles["option"]}>
                                <Option idx={idx+1} onClose={closeOptionHandler}/>
                            </div>
                        ))
                    }
                    
                    
                </div>}
            </div> */}

{/* <div className={styles["option"]}>
                        <Option idx={2}/>
                    </div> */}