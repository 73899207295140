import { useState } from "react";
import { useDispatch } from "react-redux";
// import { deleteQuestionFromSequenceById } from "../../../../../store/QuestionSequence/QuestionSequence.slice";
// import Form from "../../Form";
import styles from "./Content.module.css";

const Content = (props)=>{
    //state for edit form
    // const [showForm,setShowForm]=useState(false);

    //to handle opening and closing of edit form
    // const toggleShowForm = ()=>setShowForm(state=>!state);

    //dispatch redux
    // const dispatch = useDispatch();


    //to delete question on delete btn click
    // function deleteQuestionHandler(e){
    //     if(!props.id) return;
        
        // dispatch(deleteQuestionFromSequenceById(props.id))
        // .then(res=>{
        //     //fetch questionare again
        //     alert("Question removed succesfully!");
        //     if(props.refresh) props.refresh();
        // })
        // .catch(err=>console.log(err));
    // }


    //markup
    return <div className={styles["container"]}>
            {/* {showForm && <div className={styles["form"]}>
                <div className={styles["form_inner"]}>
                    <Form onClose={toggleShowForm} mode="edit" id={props.id} refresh={props.refresh}/>
                </div>
            </div>} */}
            {/* <div className={styles["heading"]}>{props.language}</div> */}
            <div className={styles["questions"]}>
                <div className={styles["sub_heading"]}>Question: </div>
                <div className={styles["content"]}>{props.question}</div>
            </div>
            <div className={styles["questions"]}>
                <div className={styles["sub_heading"]}>Answer: </div>
                <div className={styles["content"]}>{props.answer && props.answer.join(', ')}</div>
            </div>
            {props.options && <div className={styles["options"]}>
                <div className={styles["sub_heading"]}>Options</div>
                {
                    props.options && props.options.map((el,idx)=>{
                        return <div className={styles["option"]}>
                            <div className={styles["sub_oheading"]}>
                                Option {idx+1}:
                            </div>
                            <div className={styles["content"]}>
                                {el}
                            </div>
                        </div>;
                    })
                }
            </div>
            }
            {/* <div className={styles["cta"]}>
                <div className={styles["remove"]}>
                    <div onClick={deleteQuestionHandler}>Remove</div>
                </div>
                <div className={styles["edit"]}>
                    <div onClick={toggleShowForm}>Edit</div>
                </div>
            </div> */}
    </div>
}
export default Content;