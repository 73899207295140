import React from 'react'
import { color } from './Color';

const RadioBoxContainer = (props) => {
    const { type, item, questionData, index, setQuestionData, reRender, setReRender } = props;
    return (
        <div style={styles.checkboxContainer}>
            {
                type == "I-E" || type == "OI" ?
                    item.options.map((option, i) => {
                        return (
                           
                            <div >
                                <input type="radio"
                                    value={item.answer}
                                    checked={item.answer === option ? true : false}
                                    color={color.Primary}
                                    onChange={() => {
                                        const data = questionData
                                        item.answer = option
                                        setQuestionData([...data]);
                                        setReRender(!reRender)
                                    }}
                                />
                                <span >{option}</span>
                            </div>
                        )
                    })
                    :
                    item.hindiOptions.map((option, i) => {
                        return (
                           
                            <div style={styles.checkboxContainer}>
                                <input type="radio"
                                    value={item.answer}
                                    checked={item.answer === option ? true : false}
                                    color={color.Primary}
                                    onChange={() => {
                                        const data = questionData
                                        item.answer = option
                                        setQuestionData([...data]);
                                        setReRender(!reRender)
                                    }}
                                />
                                <span >{option.text}</span>
                            </div>
                        )
                    })
            }
        </div>
    )
}

export default RadioBoxContainer;

const styles = {
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center"
    },
    checkboxText: {
        fontSize: 18,
        color: color.Black
    }
}