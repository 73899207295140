export function formatQuestions(questions,type="I-E"){
    if(!questions) return [];
    return questions.map((el,idx)=>{
        let category;
        if(el.category=="I") category="Indian";
        if(el.category=="OI") category="Non-Indian";
        if(el.category=="B") category="Both";
    
        // 'Text','Radio','Checkbox','Yes / No'
        let qtype;
        if(el.type=="input") qtype="Text";
        if(el.type=="radio") qtype="Radio";
        if(el.type=="checkbox") qtype="Checkbox";
        if(el.type=="bool") qtype="Yes / No";

        let question=[];

        let questionCategory = el.questionCategory;

        if(type!="I-H"){
            question=[
                {
                    language:'English',
                    question:el.question,
                    options:el.options
                }
            ];
        }
        else{
            question=[{
                language:'Hindi',
                question:el.hindiQuestion,
                options:el.hindiOptions
            }]
        }

        return {
            qtype,
            category,
            question,
            _id:el._id,
            questionCategory
        }
    })
}



// {
    //         qno:1,
    //         qtype:'singleselect',
    //         question:[
    //             {
    //                 language:'Hindi',
    //                 question:'भारत की राजधानी क्या है?',
    //                 options:['नई दिल्ली','मुंबई','चेन्नई','कोलकाता'],
    //             },
    //         ],
    //         category:'Indian - Hindi'
    //     },
    //     {
    //         qno:2,
    //         qtype:'singleselect',
    //         question:[
    //             {
    //                 language:'English',
    //                 question:'What is captial of India?',
    //                 options:['New Delhi','Mumbai','Chennai','Kolkata'],
    //             },
    //         ],
    //         category:'Indian - English'
    //     },
    //     {
    //         qno:3,
    //         qtype:'text',
    //         question:[
    //             {
    //                 language:'English',
    //                 question:'What is your name?',
    //             },
    //         ],
    //         category:'Non-Indian'
    //     },
    //     {
    //         qno:4,
    //         qtype:'multiselect',
    //         question:[
    //             {
    //                 language:'English',
    //                 question:'Which cities do you like?',
    //                 options:['New Delhi','Mumbai','Chennai','Kolkata'],
    //             },
    //         ],
    //         category:'Indian - English'
    //     }