import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAdd } from "react-icons/md";

function FAQ() {
    const [faq, setFaq] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [formData, setFormData] = useState({
        id:"",
        question: "",
        answer: ""
      });

      const handleFAQ = async() => {
        try {
            const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/faq/all`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
            console.log("FAQ Fetched successfully", response.data.data.faqsData)
            setFaq(response.data.data.faqsData);
        } catch (error) {
            console.log("Error fetching FAQ", error);
        } finally {
          setLoading(false);
      }
      }

      useEffect(() => {
        handleFAQ();
      },[]);

      const handleAddDataClick = () => {
        setShowModal(true);
      };
      const handleCloseModal = () => {
        setFormData({
          id:"",
          question: "",
          answer: ""
        });
        setShowModal(false);
        setEditModal(false);
        setDeleteModal(false);
        // setSelected(null);
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleForm = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem("jwt");
        
        if (!authToken) {
          toast.error("No authentication token found. Please log in.");
          return;
        }
      
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/faq`,
            {
                question: formData.question,
                answer: formData.answer 
            },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
              },
            }
          );
      
          toast.success("FAQ added successfully");
          handleFAQ();
          handleCloseModal();
        } catch (error) {
            console.log("Failed adding FAQ", error)
            toast.error("Failed adding FAQ");
          } 
        
      };

  // edit entry
  const handleEditClick = (data) => {
    setFormData({
      id: data._id || "",
      question: data.question || "",
      answer: data.answer || "",
      
    });
    setEditModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");
    
    if (!formData.id) {
      toast.error("Error updating data");
      return;
    }
  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/faq/${formData.id}`,
        {
            question: formData.question,
            answer: formData.answer
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      toast.success("FAQ updated successfully");
      handleFAQ();
      handleCloseModal();
    } catch (error) {
        console.log("Failed updating FAQ", error)
        toast.error("Failed updating FAQ");
      } 
    
  };
    //   Delete API
    const handleDeleteClick = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
      };

      const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem("jwt");

          const response = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/faq/${deleteId}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
    
          toast.success(" FAQ Deleted Successfully");
          handleFAQ();
          setDeleteId("");
          handleCloseModal();
        } catch (error) {
            console.log("Failed deleting FAQ", error);
          toast.error("Failed Deleting FAQ")
        }
      };
      
  return (<div style={{background: "#eaeaea", width:"100%", height:"100%"  }}>
  <div style={{width:"80%", marginInline:"auto", paddingTop:"50px",background: "#eaeaea"  }}>
  <ToastContainer/>
   <div
        style={{
          display: "flex",
          flexDirection:"column",
          width: "100%",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <h1
          style={{
            display: "flex",
            fontSize: "calc(1.375rem + 1.5vw)",
            fontWeight: "600",
            justifyContent: "start",
            alignItems: "center",

          }}
        >
          FAQ{" "}
        </h1>
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "10px" }}>
          <button style={{
    background: "var(--green)",
    fontSize: "18px",
            border: "none",
            color: "white",
            padding: "5px 20px",
            display:"flex",
            alignItems:"center"
          }}
            onClick={handleAddDataClick}
            >Add FAQ</button>
        </div>
      </div>
    <div style={{ width: "100%", overflow: "scroll", maxHeight: "70vh", paddingInline:"20px", backgroundColor: "#F8F9FA" }}>
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <thead style={{ position: "sticky", top: "-1px" }}>
                            <tr style={{ fontSize: "18px", fontWeight: "600", paddingTop: "15px" }}>
                                <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }}>FAQ ID</th>
                                <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }}>Question</th>
                                <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }}>Answer</th>
                                <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody style={{ overflowY: "auto" }}>
                            {loading ? (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>Loading...</td>
                                </tr>
                            ) : faq.length === 0 ? (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>No FAQs available.</td>
                                </tr>
                            ) : (
                                faq.map((data, index) => (
                                    <tr key={index} style={{ background: "#F8F9FA" }}>
                                        <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?._id}</td>
                                        <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?.question}</td>
                                        <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?.answer}</td>
                                        <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <FaRegEdit size={20} color={"var(--green)"} style={{ marginInline: "5px", display: "flex" }} onClick={() => handleEditClick(data)} />
                                                <RiDeleteBin5Line size={20} color={"red"} style={{ marginLeft: "10px" }} onClick={() => handleDeleteClick(data._id)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
  </div>  
        {/* Add new data */}
        {showModal && ( 
       <div
       style={{
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 99,
       }}
     >
       <div
         style={{
           backgroundColor: "white",
           padding: "20px",
           borderRadius: "5px",
           width: "450px",
         }}
       >
         <form onSubmit={(e) => { handleForm(e) }}>
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Add FAQ</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Question</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter question"
               value={formData.question}
               name="question"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Answer</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter answer"
               value={formData.answer}
               name="answer"
               onChange={handleChange}
               required
             />
           </div>
     
          
     
           <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                  background: "var(--green)",
                 border: "none",
                 borderRadius: "5px",
                 padding: "5px 10px",
                 color: "white",
                 width:"100px",
                 justifyContent:"center",
                 cursor: "pointer"
               }}
               type="submit"
             >
               Add
             </button>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                 background: "#d3d3d3",
                 border: "none",
                 borderRadius: "5px",
                 width:"100px",
                 justifyContent:"center",
                 padding: "5px 10px",
                 color: "black",
                 cursor: "pointer"
               }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     
      )}
        {/* Update data */}
        {editModal && ( 
       <div
       style={{
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 99,
       }}
     >
       <div
         style={{
           backgroundColor: "white",
           padding: "20px",
           borderRadius: "5px",
           width: "450px",
         }}
       >
         <form onSubmit={(e) => { handleUpdate(e) }}>
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Edit FAQ</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Question</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter question"
               value={formData.question}
               name="question"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Answer</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter answer"
               value={formData.answer}
               name="answer"
               onChange={handleChange}
               required
             />
           </div>
         
     
           <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                display: "flex",
                margin: "10px",
                fontSize: "16px",
                background: "var(--green)",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                color: "white",
                width:"100px",
                justifyContent:"center",
                cursor: "pointer"
              }}
               type="submit"
             >
               Update
             </button>
             <button
               style={{
                display: "flex",
                margin: "10px",
                fontSize: "16px",
                background: "#d3d3d3",
                border: "none",
                borderRadius: "5px",
                width:"100px",
                justifyContent:"center",
                padding: "5px 10px",
                color: "black",
                cursor: "pointer"
              }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     
      )}

        {/* Delete Entry */}
        {deleteModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "450px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Are you sure you want to delete?</div>

            <div style={{ display: "flex", justifyContent: "center" }}>
            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "var(--green)", width:"100px",justifyContent:"center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "white", cursor: "pointer" }} onClick={handleDelete}>Delete</button>
            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "#d3d3d3", width:"100px",justifyContent:"center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "black", cursor: "pointer" }} onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
  </div>
  </div>
  )
}

export default FAQ