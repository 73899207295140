import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from './AllBlogs.module.css';
import Header from "../Header";
import Footer from "../Footer";
import parse from 'html-react-parser';


const AllBlogs = () => {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleBlog = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/blog?domain=abeautifulmess.com&maxResults=100`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setAllData(response.data.data.items);
    } catch (error) {
      console.log("Error fetching blogs", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleBlog();
    window.scrollTo(0, 0);
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  // const [a, setA] = useState([]);
  // const htmlString = allData?.map(i => {
  //   // const imgTags = ; // Extract <img> tags
  //   setA(extractImgTags(i?.content));
  // });

  const extractImgTags = (htmlString) => {
    const imgRegex = /<img[^>]*>/g; // Regular expression to match <img> tags
    const imgTags = htmlString.match(imgRegex); // Extract <img> tags
    return imgTags || [];
  };

  return (<>
    <Header />
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loader}>Loading...</div>
      ) : (
        <>
          <h2 className={styles.heading}>All Blogs</h2>
          <div className={styles.cardContainer}>
            {allData?.map((item, index) => (
              <div key={index} className={styles.card}>
                <div> {parse(extractImgTags(item?.content)[0])}</div>
                <div className="">
                  <p className={styles.cardTitle}>{truncateText(item?.title, 70)}</p>
                  <Link to={`/blog/${item?.blog?.id}/${item.id}`} className={styles.readMore}>Read More</Link>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
    <Footer />
  </>
  );
};

export default AllBlogs;
