import axios from "axios";


const url = process.env.REACT_APP_BASE_URL;
export const adminAxios = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json"
  },
});

adminAxios.interceptors.request.use((request) => {
  let token = localStorage.getItem('jwt');
  request.headers["Authorization"] = token ? "Bearer " + token : "";
  return request; 
});
    
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2Mzk2Nzg1MzI1MTRkNjVlMTRlMjJmZmEiLCJpYXQiOjE2NzExMDA3MDgsImV4cCI6MTcwMjY1ODMwOH0.IMmopizqP4ZG2T_gypTpzWbeSRj6WhxEM6jnjlJA2_w