import axios from "axios";

// https://8n5gyn4hf7.execute-api.ap-south-1.amazonaws.com/api
const url=process.env.REACT_APP_BASE_URL;
export const defaultAxios = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});

// defaultAxios.interceptors.response.use(
//   (response) => {
//     if (response.data?.status === 1) return response.data;
//     return Promise.reject(response.data?.err);
//   },
//   (error) => {
//     const { response } = error;

//     const message = response?.data?.err || "Something Went Wrong";
//     return Promise.reject(message);
//   }
// );