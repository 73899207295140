import React from 'react'
import styles from "./youtube.module.css"

function YoutubeSection({url}) {
    return (
        <div className={styles["video"]}>
            <iframe 
            className={styles["video-container"]} 
            src={url} 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen />
        </div>
    )
}

export default YoutubeSection