import { useState } from "react";
import styles from "./Select.module.css";
import {AiFillCaretDown} from 'react-icons/ai';
import { useEffect } from "react";

const options = [
    'New Delhi',
    'Mumbai',
    'Kolkata',
    'Bengaluru'
]
const Select = (props)=>{
    const [selected,setSelected]=useState(props.options?props.options[0]:"");
    const [expand,setExpand]=useState(false);
    
    useEffect(()=>{
        if(props.init)
        setSelected(props.init);
    },[props.init]);
    function handleSelect(value,idx){
        // console.log("idx",idx);
        setExpand(false);
        // if(value==selected) return;

        setSelected(value);
        props.onChange({target:{value,meta:idx}});
    }
  
    return <div className={styles["container"]}>
        <div className={styles["main"]} onClick={(e)=>setExpand(state=>!state)}><div>{selected}</div><div className={expand?styles["rotate"]:""} ><AiFillCaretDown/></div></div>
        {expand && <div className={styles["options"]}>
            {props.options && props.options.map((el,idx)=>{
                return <div className={styles["option"]} onClick={()=>handleSelect(el,idx)}>
                    {el}
                </div>
            })}
        </div>}
    </div>
}
export default Select;