import { useState } from "react";
import Option from "../../Option";
import styles from "./Content.module.css";

const Content = (props)=>{


    //To add a input when add option clicked
    const addOptionHandler = (e) =>{
        if(!props.options || props.options.findIndex(el=>el=="")!=-1) return;
        props.setOptions(state=>[...state,""]);
    }

    //to remove a input when close is clicked
    const closeOptionHandler = (idx) =>{
        props.removeOption(idx);
    }

    return <div className={styles["inner"]}>
                <h3>{props.language}</h3>
                <div className={styles["add_question"]}>
                    <div className={styles["aq_text"]}>Question</div>
                    <div className={styles["aq_inp"]}>
                        <textarea value={props.question} onChange={(e)=>props.setQuestion(e.target.value)}/>
                    </div>
                </div>
                {props.showOptions && <div className={styles["options"]}>
                    <div className={styles["o_text"]}>Options</div>
                    <div className={styles["o_sub-text"]}>
                        <div className={styles["o_sub-text--content"]} onClick={addOptionHandler}>Add Option</div>
                    </div>
                    {
                        props.options && props.options.length > 0 && props.options.map((el,idx)=>(
                            <div className={styles["option"]}>
                                <Option key={idx} idx={idx+1} onClose={closeOptionHandler} setOptions={props.setOptions} option={el}/>
                            </div>
                        )) 
                    }
                    
                    {/* <div className={styles["option"]}>
                        <Option idx={2}/>
                    </div> */}
                </div>}
            </div>;
}
export default Content;