import { useSearchParams } from "react-router-dom";
import styles from "./Table.module.css";
const TableHeader = (props)=>{
    return <thead class="thead-dark">
        <tr>
            {props.data && props.data.map((el,idx)=>{
                return <th style={{minWidth:"180px"}} scope="col">{el}</th>
            })}
        </tr>
    </thead>
}

const TableRow = (props)=>{
   
    const callDelete = (e)=>{
        props.deleteById(props.data[0]);
    }
    return <tr>
        {props.data && <th scope="row">{props.data[0]}</th>}
        {
            props.data && props.data.map((el,idx)=>{
                if(idx==0) return <></>;

                if(idx==props.data.length-1 && props.makeLastBtn) return <td><div className={styles["link"]} onClick={callDelete}>{el}</div></td>


                // if(idx==4) return <td><div className={styles["tag"]}>{el}</div></td>
                return <td>{el}</td>
            })
        }
    </tr>
}
const Table=(props)=>{
    let rowData = props.data?props.data:[];
    if(rowData) rowData=rowData.length>1?rowData.slice(1):rowData;

    return <div className={styles["container"]}>
    <div className={styles["wrapper"]}>
    <div className={styles["content"]}>
       <table class="table table-light">
            <TableHeader data={props.data?props.data[0]:[]}/>
            <tbody>
                {
                    rowData && rowData.map((el,idx)=>{
                        return <TableRow data={el} setShowDetails={props.setShowDetails} makeLastBtn={props.makeLastBtn} setShowCQ={props.setShowCQ} setShowCA={props.setShowCA} deleteById={props.deleteById}/>
                    })
                }
            </tbody>
        </table>
    </div>
    </div>
    {/* <div className={styles["options"]}>
        <div className={styles["left"]}>Showing page 1 of 16 </div>
        <div className={styles["right"]}>
            <div>Prev</div>
            <div>Next</div>
        </div>
    </div> */}
    </div>
}
export default Table