import { useState } from "react";
import styles from "./Switch.module.css";
const Switch = (props)=>{
    const [on,setOn]=useState(props.checked);

    const handleToggle = (e)=>{
        props.changeState(!on);
        setOn(state=>!state);
    }

    return <div className={on?`${styles["container"]} ${styles["on-container"]}`:styles["container"]} onClick={handleToggle}>
        <div className={on?`${styles["content"]} ${styles["on"]}`:styles["content"]}>
            
        </div>
    </div>
}
export default Switch;