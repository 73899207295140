import Form from "./Form";
import Questions from "./Questions";
import styles from "./QuestionaireManagement.module.css";
import { useState } from "react";
import Select from "../../elements/Select";
import CategoryData from "./Category.data";
import AnswerTypeData from "./AnswerType.data";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchChartQuestionSequenceByType } from "../../../store/ChartQuestionSequence/ChartQuestionSequence.slice";


const QuestionaireManagement = (props)=>{
    //dispatch
    const dispatch = useDispatch();

    //state variables
    const [showForm,setShowForm]=useState(false);
    const [draggable,setDraggable]= useState(true);
    const [category,setCategory]= useState("Indian - English");
    const [answerType,setAnswerType] = useState("All");
    const [refresh,setRefresh] = useState(true);
    
    //used to refetch questionare
    const refreshFn = ()=>setRefresh(state=>!state);

    //category for questionare
    function categoryChangeHandler(e){
        setCategory(e.target.value);
    }

    //show form for add button
    const toggleShowForm = ()=>setShowForm(state=>!state);

    //on input type change
    const onAnswerChange = (e)=>{
        setAnswerType(e.target.value);
        setDraggable(()=>e.target.value==="All");
    }

    //to fetch entire questionare
    useEffect(()=>{
        let cat;
        if(category=="Indian - Hindi") cat="I-H";
        if(category=="Indian - English") cat="I-E";
        if(category=="Non - Indian") cat="OI";

        dispatch(fetchChartQuestionSequenceByType(cat)); 

    },[category,refresh]);


    //markup
    return  <div className={styles["container"]}>
        {showForm && <div className={styles["form"]}>
            <div className={styles["form_inner"]}>
                <Form onClose={toggleShowForm} refresh={refreshFn}/>
            </div>
        </div>}
    <div className={styles["content"]}>
        <div className={styles["headings"]}>
            <h1>Chart questionnaire management</h1>
        </div>
        <div className={styles["inner"]}>
            <div className={styles["upper"]}>
                <div className={styles["nav"]}>
                    <div className={styles["left"]}>
                        <div className={styles["category"]}>
                            <Select options={CategoryData} onChange={categoryChangeHandler}/>
                        </div>
                        <div className={styles["category"]}>
                            {/* <Select options={AnswerTypeData} onChange={onAnswerChange}/> */}
                        </div>
                    </div>
                    <div className={styles["right"]}>
                        <div onClick={toggleShowForm}>Add Question</div>
                    </div>
                </div>
            </div>
            <div className={styles["lower"]}>
                <Questions draggable={draggable} type={answerType} ctype={category} refresh={refreshFn}/>
            </div>
        </div>
    </div>
</div>
}
export default QuestionaireManagement;