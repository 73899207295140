import { useEffect, useState } from "react";
import styles from "./Question.module.css";
import Ckeditor from "../../../elements/Ckeditor";

const Question = (props)=>{
    const noOfOptions = props?.question?.options?.length || 1;
    const [showText,setShowText] = useState('');
    const [answer,setAnswer] = useState(Array(noOfOptions).fill(''));
    const [remark,setRemark] = useState("")

    let show=false;
    if(props.question.options)
    show = props?.question?.options[props.question.options.length-1].toLowerCase()=="other";

    const [showOthers,setShowOthers]=useState(show);

    useEffect(()=>{
        // console.log("change");
        // console.log(answer,props.idx);
        props.onAnswerChange(answer,props.idx);
    },[answer]);

    useEffect(()=>{
        // console.log("change");
        // console.log(answer,props.idx);
        props.onAnswerChange(remark,props.idx);
    },[remark]);

    const onChangeHandler = (e,idx,type,isOption,val)=>{
        setAnswer(state=>{
            // console.log(val);
            const arr = [...state];

            if(arr.length-1 < idx)
            arr=[...arr,...Array(idx-arr.length-1).fill('')];

            if(type=="checkbox"&&isOption)
            if(e.target.checked)
            arr[idx]=val;
            else
            arr[idx]="";

            if(type=="checkbox"&&!isOption)
            arr[idx]=e.target.value;

            if(type=="text")
            arr[idx]=e.target.value;

            // console.log(arr);
            return arr;
        })
    }
   
    return <div className={styles["container"]}>
        <div className={styles["heading"]}>
            <div>{props?.question?.question}</div>
        </div>
        {!props.text && <div className={styles["answers"]}>
            {props?.question?.options?.map((el,idx)=>{

            if(el.toLowerCase()!="other")
            return <div className={styles["answer"]}>
                <div>
                    <input type="checkbox" onChange={(e)=>onChangeHandler(e,idx,"checkbox",true,el)}/>
                </div>
                <div>
                   {el}
                </div>
            </div>;

            return <></>;
            }
            )}
            {showOthers && <><div className={styles["answer"]}>
                <div>
                    <input type="checkbox" onChange={(e)=>setShowText(e.target.checked)} />
                </div>
                <div>
                    others
                </div>
            </div>
            {showText && <div>
                <textarea type="text" className={styles["textbox"]} placeholder="Write your answer" onChange={(e)=>onChangeHandler(e,props?.question?.options?.length-1,"checkbox",false)}/>
            </div>}</>
            }
        </div>}
        {props.text && <div className={styles["answers"]}>
            <Ckeditor description={remark} setDescription={setRemark} />
            {/* <textarea type="text" className={styles["textbox"]} placeholder="Write your answer" onChange={(e)=>onChangeHandler(e,0,"text")}/> */}
        </div>}
    </div>;
}
export default Question;