import styles from "./DiseaseCard.module.css";

const DiseaseCard = (props)=>{
    console.log("props",props)
    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <img src={props?.mediaLink} alt={props?.diseasesOf}/>
            <h6>{props.diseasesOf}</h6>
            <ul>
                {props?.diseaseList?.map((el,idx)=>{
                    return <li>{el}</li>
                })}
            </ul>
        </div>
    </div>
}
export default DiseaseCard;