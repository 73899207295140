import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { membershipGet, membershipPost, membershipUpdate } from "../../../../utils/global.apis";
import Select from "../../../elements/Select";
import Content from "./Content";
import styles from "./Form.module.css";

const Form = (props)=>{

    const [categoryType,setCategoryType] = useState('Indian');
    const [answerType,setAnswerType] = useState('INR');
    const [duration,setDuration]=useState("");
    const [price,setPrice]=useState("");
    const [question,setQuestion]=useState("");
    const [options,setOptions]=useState(['']);
    const [hindiQuestion,setHindiQuestion]=useState("");
    const [hindiOptions,setHindiOptions]=useState(['']);
    const [id,setId]=useState(null);

    //to handle category type change
    const categoryTypeChangeHandler= (e)=>{
        setCategoryType(e.target.value);
    }


    //On answer i.e input type change
    const answerTypeChangeHandler= (e)=>{
        //set state
        setAnswerType(e.target.value);

    }

    const showOptions=()=>{};
    

    //When clicked on close option button
    const removeOption = (idx)=>{
        if(!options || options.length <= 1) return;
        setOptions(state=>{
            let arr=[...state];
            arr.splice(idx-1,1);
            return [...arr];
        });
    }

    const removeHindiOption = (idx)=>{
        if(!hindiOptions || hindiOptions.length <= 1) return;
        setHindiOptions(state=>{
            let arr=[...state];
            arr.splice(idx-1,1);
            return [...arr];
        });
    }

    //To pre load fields in case of edit
    useEffect(()=>{
        if(props.mode!="edit") return;

        membershipGet(props.id)
        .then(res=>{
            let data=res.data.data;
            // console.log(data);
            setId(data._id);
            

            // let qtype;
            // if(data.type=="") qtype="Text";
            // if(data.type=="radio") qtype="Radio";
            // if(data.type=="checkbox") qtype="Checkbox";
            // if(data.type=="bool") qtype="Yes / No";
            setAnswerType(data.currencyType);
            answerTypeChangeHandler({target:{value:data.currencyType}});

            let category;
            if(data.type=="I") category="Indian";
            if(data.type=="OI") category="Non-Indian";
            if(data.type=="B") category="Both";
            // console.log("category:",category);
            setCategoryType(category);

            // console.log(data);
            setPrice(data.price);
            setDuration(data.duration);
            setOptions(data.includes?data.includes:[""]);
            setHindiOptions(data.hindiIncludes?data.hindiIncludes:[""]);

            setQuestion(data.name);
            setHindiQuestion(data.hindiName);
        })
        .catch(err=>console.log(err));
    },[]);

    //When clicked on CTA i.e BTN
    const postQuestionHandler = async (e)=>{
        try{
        
        //Translate local variables acc to API body
        let type;
        if(categoryType=="Indian") type="I";
        if(categoryType=="Non-Indian") type="OI";
        // if(categoryType=="Both") type="B";
    
    
        let currencyType=answerType;

        // if(answerType=="Text") type="input";
        // if(answerType=="Radio") type="radio";
        // if(answerType=="Checkbox") type="checkbox";
        // if(answerType=="Yes / No") type="bool";

        let flag=false;
        if(answerType=="Text" || answerType=="Yes / No") flag=true;
        options.map(el=>{if(el!==""&&question!="") flag=true; return [];});
        hindiOptions.map(el=>{if(el!==""&&hindiQuestion!="") flag=true; return [];});

        if(!flag) throw "Atleast 1 option need to be filled";

        const data = {
            type,
            currencyType,
            name:question,
            hindiName:hindiQuestion,
            includes:options,
            hindiIncludes:hindiOptions,
            price,
            duration
        };

        //Decide is it update or create
        let res;
        if(props.mode!="edit")
        res = await membershipPost(data);
        else
        res = await membershipUpdate(data,id);
        
        // console.log(res);
        if(!res)  throw "Something went wrong!";
        // console.log("still");
        //fetch entire questionare
        if(props.refresh) props.refresh();
        
        //close form popup
        props.onClose();
        if(props.mode!="edit")
        alert("Question added succesfully!");
        else
        alert("Question updated succesfully!");

        props.refreshFn();

        }catch(err){
            alert(err);
            console.log(err);
        }
    }

    return <div className={styles["container"]}>
        <div className={styles["close"]} onClick={props.onClose}><AiFillCloseCircle/></div>
        <div className={styles["heading"]}>{props.mode!="edit"?"Add a question":"Edit a question"}</div>
        <div className={styles["select_answer"]}>
                <div className={styles["sa_text"]}>Select Category</div>
                    <div className={styles["sa_inp"]}>
                        <Select options={['Indian','Non-Indian']} onChange={categoryTypeChangeHandler} init={categoryType}/>
                    </div>
                </div>
                <div className={styles["select_answer"]}>
                    <div className={styles["sa_text"]}>Select Currency Type</div>
                    <div className={styles["sa_inp"]}>
                        <Select options={['INR','USD']} onChange={answerTypeChangeHandler} init={answerType}/>
                    </div>
                </div>
                <div className={styles["select_answer"]}>
                <div className={styles["sa_text"]}>Duration (Days)</div>
                    <div className={styles["sa_inp"]}>
                        <input type="text" value={duration} onChange={(e)=>setDuration(e.target.value)}/>
                    </div>
                </div>
                <div className={styles["select_answer"]}>
                <div className={styles["sa_text"]}>Price (Selected type)</div>
                    <div className={styles["sa_inp"]}>
                        <input type="text" value={price} onChange={(e)=>setPrice(e.target.value)}/>
                    </div>
                </div>
        <div className={styles["content"]}>
            <Content language="English" showOptions={showOptions} setQuestion={setQuestion} question={question} setOptions={setOptions} removeOption={removeOption} options={options}/>
        </div>
        {categoryType!="Non-Indian" && <div className={styles["content"]}>
            <Content language="Hindi" showOptions={showOptions} setQuestion={setHindiQuestion} question={hindiQuestion} setOptions={setHindiOptions} removeOption={removeHindiOption} options={hindiOptions}/>
        </div>}
        <div className={styles["cta"]}>
            <div className={styles["btn"]} onClick={postQuestionHandler}>{props.mode!="edit"?"Submit":"Save"}</div>
        </div>
    </div>;
}
export default Form;