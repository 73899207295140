import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import 'font-awesome/css/font-awesome.min.css';
import axios from "axios";
import { useEffect, useState } from "react";

const Footer = (props)=>{
    const [allData, setAllData] = useState({});
  
    const handleFooter = async () => {
      try {
        const authToken = localStorage.getItem("jwt");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/footer`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        console.log("Footer Fetched successfully", response.data.data.footer);
        setAllData(response.data.data.footer);
      } catch (error) {
        console.log("Error fetching footer", error);
      }
    };
  
    useEffect(() => {
      handleFooter();
    }, []);

    return <footer className={styles["container"]}>
        <div className={styles["upper"]}>
            {/* <div className={styles["section"]}>
                <h5>Heading A</h5>
                <div className={styles["details"]}>
                    <div>Data 1</div>
                    <div>Data 2</div>
                    <div>Data 3</div>
                </div>
            </div> */}
            <div className={styles["section"]}>
                <h5>Contact Us</h5>
                <div className={styles["details"]} >
                    <div className={styles["detail_links"]}>
                        <a href="mailto:healinroots@gmail.com">admin@healinroots.com</a>
                    </div>
                    <div className={styles["detail_links"]}>
                        <a href="tel:+918766334676">+91 87663 34676</a>
                    </div>
                    <div className={styles["detail_links"]}>
                        <a href="tel:+919354663328">+91 93546 63328</a>
                    </div>
                    <div className={styles["detail_links"]}>
                    <a href="tel:01141099548">(011) 410 99548</a>
                    </div>
                    <div className={styles["detail_links"]} style={{gap:"15px"}}>
                    <p onClick={()=>{window.open("https://linktr.ee/healinroots?utm_source=linktree_profile_share","_blank")}} style={{cursor:"pointer"}}>LinkTree</p>
                    </div>
                    <div className={styles["detail_links"]} style={{gap:"15px"}}>
                    <p onClick={()=>{window.open("https://maps.app.goo.gl/JB7r31rNtbvEFYSV9?g_st=ic","_blank")}} style={{cursor:"pointer"}}>Visit Our Clinic</p>
                    </div>
                    <div className={styles["detail_links"]} style={{ gap: "15px" }}>
                            {allData.socialMedia?.map((social, index) => {
                                let iconClass = '';
                                switch (social.linkName) {
                                    case 'Facebook':
                                        iconClass = 'fa-facebook-official';
                                        break;
                                    case 'Whatsapp':
                                        iconClass = 'fa-whatsapp';
                                        break;
                                    case 'Instagram':
                                        iconClass = 'fa-instagram';
                                        break;
                                    case 'Email':
                                        iconClass = 'fa-envelope-o';
                                        break;
                                    case 'Telegram':
                                        iconClass = 'fa-telegram';
                                        break;
                                    default:
                                        iconClass = '';
                                }
                                return (
                                    iconClass && (
                                        <i
                                            key={index}
                                            className={`fa ${iconClass} fa-2x`}
                                            style={{ color: "#04686C", cursor: "pointer" }}
                                            aria-hidden="true"
                                            onClick={() => { window.open(social.linkUrl, "_blank") }}
                                        ></i>
                                    )
                                );
                            })}
                        </div>

                </div>
            </div>
            {allData.footerLinks?.map((footerLink, index) => (
                    <div className={styles["section"]} key={index} >
                        <h5>{footerLink.title}</h5>
                        <div className={styles["details"]}>
                            {footerLink.link.map((link, idx) => (
                                <div key={idx} className={styles["detail_links"]}>
                                    <Link to={link.linkUrl}>{link.linkName}</Link>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
        </div>
        <div className={styles["lower"]}>
        <p>{allData?.address}</p>
        </div>
        <div className={styles["lower"]}>
            {allData?.footerBottomLine}
        </div>
    </footer>;
}
export default Footer;