import styles from "./Diseases.module.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import DiseaseCard from "./DiseaseCard";
import Stomach from "./../../../assets/stomach.png";
import DiseasesData from "./Diseases.data";
import axios from "axios";

const Diseases = (props)=>{
    const [noOfSlides, setNoOfSlides] = useState(3);

    useEffect(()=>{
        if(window.innerWidth<600){
            setNoOfSlides(state=>1);
        }
        else if(window.innerWidth<900){
            setNoOfSlides(state=>2);
        }
    },[window.innerWidth]);
    var settings = {
        // dots: true,
      infinite: true,
      slidesToShow: noOfSlides,
      slidesToScroll: 1,
      autoplay: true,
      speed: 5000,
      autoplaySpeed: 2000,
    //   cssEase: "linear"
      };

      const [allData, setAllData] = useState([]);

      const handleDiseasesWeCure = async() => {
        try {
            const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/diseasewecure/all`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
            console.log("Diseases We Cure Fetched successfully1", response.data.data)
            setAllData(response.data.data);
        } catch (error) {
            console.log("Error fetching Diseases We Cure", error);
        } 
      }

      useEffect(() => {
        handleDiseasesWeCure();
      },[]);


    return <div className={styles["container"]}>
        <div className={styles["heading"]}><h2>Diseases We Cure</h2></div>
        <div className={styles["cards"]}>
        <Slider {...settings}>
            {
                allData.map(el=>(
                    <div className={styles["card"]}>
                            <div className={styles["cardcontain"]}><DiseaseCard {...el}/></div>
                    </div>
                ))
            }
        </Slider>
        {/* <div>
            <img src={require('../../../assets/membership.jpeg')}
            style={{
                width:"100%",
                maxHeight:"auto",
                marginTop:40
            }}
            />
        </div> */}
        </div>
    </div>
}
export default Diseases;