import { useEffect, useState } from "react";
import { deleteTimeSlotById, getTimeSlotsByType, timeSlotPost } from "../../../utils/global.apis";
import Select from "../../elements/Select";
import styles from "./ScheduleManagement.module.css";
import ScheduleRow from "./ScheduleRow";
import Table from "./Table";

const days=['Sunday','Monday','Tuesday','Wednesday','Friday','Saturday'];


function formatAndSetData (data){
    let newData = [
        ["Id","From",'To',"Type","Delete"],
    ];
    let d=data.map((el)=>{
        return [el._id,el.from,el.to,el.type=="I"?"Indian":"Non-Indian","delete"];
    });

    newData = [...newData,...d];
    // console.log(newData);
    return newData;
}

const ScheduleManagement = (props)=>{

    const [daily,setDaily]=useState(true);
    const [cat,setCat]=useState("Indian");
    const [refresh,setRefresh]=useState(true);
    const toggleRefresh = ()=>setRefresh(state=>!state);

    const [from,setFrom] = useState(["",""]);
    const [to,setTo] = useState(["",""]);

    const [data,setData]=useState([
        ["Id","From",'To',"Type","Delete"],
        ["abba","10:00",'12:00',"Non-Indian","Delete"],
    ]);

    useEffect(()=>{
        let type;
        type=cat=="Indian"?"I":"OI";

        getTimeSlotsByType(type)
        .then(res=>{
            // console.log(res.data.data);
            if(!res.data.data) return;

            setData(formatAndSetData(res.data.data));

        })
        .catch(err=>console.log(err));

    },[cat,refresh]);


    const availChangeHandler = (e)=>{
        setDaily(e.target.value=="Daily");
    }
    const availCatChangeHandler = (e)=>{
        setCat(e.target.value);
    }

    const deleteById =  (id)=>{
        deleteTimeSlotById(id)
        .then(res=>{alert("Time slot deleted succesfully"); toggleRefresh(); })
        .catch(err=>console.log(err));
    }

    const saveTimeSlotHandler = async (e)=>{
        try{
            let flag=false;
            from.forEach(el=>{
                if(el==""){
                    flag=true;
                }
            });
            to.forEach(el=>{
                if(el==""){
                    flag=true;
                }
            });

            if(flag) return alert("Please fill all timestamps correctly");

            let fromFinal = from[0].length ==1? "0"+from[0]:from[0];
            fromFinal+=":";
            fromFinal+=from[1].length ==1? "0"+from[1]:from[1];

            let toFinal = to[0].length ==1? "0"+to[0]:to[0];
            toFinal+=":";
            toFinal+=to[1].length ==1? "0"+to[1]:to[1];

            let type;
            type=cat=="Indian"?"I":"OI";
            
            const data ={
                "from":fromFinal,
                "to":toFinal,
                "type":type
            }
            const res = await timeSlotPost(data);
            alert("slot saved succesfully");
            toggleRefresh();
            // console.log(res);
        }catch(err){
            console.log(err);
        }
    }
    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <div className={styles["headings"]}>
                <h1>Scheduling management</h1>
            </div>
        </div>
        <div>
            <div className={styles["table"]}>
                <div className={styles["options"]}>
                    {/* <div className={styles["option"]}>
                        <Select  options={['Daily','Weekly']} onChange={availChangeHandler}/>
                    </div> */}
                    <div className={styles["option"]}>
                        <Select  options={['Indian','Non-Indian']} onChange={availCatChangeHandler}/>
                    </div>
                </div>
                <div className={styles["card"]}>
                    <div className={styles["schedule"]}>
                        <div>
                            <ScheduleRow mode="daily" from={from} setFrom={setFrom} to={to} setTo={setTo}/>
                        </div>
                        {/* {
                            !daily && <div>
                                {days && days.map((el,idx)=>{
                                    return <div>
                                        <ScheduleRow day={el} key={idx}/>
                                    </div>
                                })}
                            </div>
                        } */}
                    </div>
                    <div className={styles["cta"]}>
                        <div className={styles["btn"]} onClick={saveTimeSlotHandler}>
                            Save
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles["table"]}>
                <div className={styles["tab"]}>
                    <Table data={data} makeLastBtn={true} deleteById={deleteById}/>
                </div>
            </div>
        </div>
    </div>;
}

export default ScheduleManagement;