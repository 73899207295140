import styles from "./Form.module.css";
// import product from "./../../../../assets/product.png";
import {FcGoogle} from "react-icons/fc";
import { useRef } from "react";


const Form = (props)=>{
    const inputRef =useRef(); 

    const handleLogin=()=>{
        // console.log(inputRef.current.value);
        // props.loginHandler({phone:inputRef.current.value})
        props.loginHandler({phone:inputRef.current.value});
    };

    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <div className={styles["details"]}>
                <div className={styles["form"]}>
                    <div className={styles["sol"]}>
                        <span>Login</span>
                        {/* or
                        <span>Signup</span> */}
                    </div>
                    <div className={styles["inp"]}>
                        <p>Mobile No or Email Address</p>
                        <input type="text" ref={inputRef} />
                    </div>
                   {/* <div className={styles["or"]}>OR</div>
                    <div className={styles["inp"]}>
                        <p>Email Address</p>
                        <input type="text" ref={input2Ref} />
                    </div> */}
                    <div className={styles["pp"]}>
                        By continuing. I agree to the <span>Term of Use</span> & <span>Privacy Policy</span>
                    </div>
                    <div className={styles["continue"]} onClick={handleLogin}>
                        LOGIN
                    </div>
                    {/* <div className={styles["or"]}>
                        <p>OR</p>
                    </div>
                    <div className={styles["google"]}>
                        <FcGoogle/> Continue with Google
                    </div> */}
                </div>
            </div>
        </div>
    </div>
}
export default Form;