
export const getToken = async () => {
  try {
    const jsonValue = await localStorage.getItem('token')
    return jsonValue != null ? jsonValue : null
  } catch (e) {
    console.log(e)
    // read error
  }

}

export const getType = async () => {
  return "I-E"
}

export const getNationality = async () => {
  try {
    const jsonValue = await localStorage.getItem('nationality')
    return jsonValue != null ? jsonValue : null
  } catch (e) {
    // read error
  }

  console.log('Done.')
}

export const getUserId = async () => {
  try {
    const jsonValue = await localStorage.getItem('userId')
    return jsonValue != null ? jsonValue : null
  } catch (e) {
    // read error
  }

  console.log('Done.')
}

export const setItem = (key, value) => {
  localStorage.setItem(key, value)
}