import React from 'react'
import styles from './fullimage.module.css'

function FullImage({imgUrl}) {
  return (
    <>
    <img src={imgUrl} className={styles["image-container"]} />
    </>
  )
}

export default FullImage