import { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Content from "../Content";
import styles from "./Question.module.css";


const Question = (props)=>{

    //expand to show question with details
    const [collapse,setCollapse] = useState(true);
   

    //markup
    return <div className={styles["container"]}>
        {!collapse && <div className={styles["content"]}>
            <div className={styles["data"]}>
                <div>Question No: {props.qno}</div>
                <div onClick={(e)=>setCollapse(true)}><AiOutlineUp/></div>
            </div>
            <div className={styles["data"]}>Question Type: {props.qtype}</div>
            <div className={styles["data"]}>Category: {props.category}</div>
            <div className={styles["qcontent"]}>
                {props.question.map((el,idx)=><Content {...el} category={props.category} id={props._id} refresh={props.refresh}/>)}
            </div>
        </div>}
        {collapse && <div className={styles["content"]}>
            <div className={styles["data"]}>
                <div>Question No: {props.qno}</div>
                <div onClick={(e)=>setCollapse(false)}><AiOutlineDown/></div>
            </div>
            <div className={styles["data"]}>{props.question[0].question}</div>
        </div>}
    </div>
}
export default Question;














// {props.category=="Indian" && 
//                 <div>English</div>
//             }
//             <div>
//                 <div>Question</div>
//                 <div>{props.question}</div>
//             </div>
//             <div>
//                 <div>Options</div>
//                 {
//                     props.options && props.options.map((el,idx)=>{
//                         return <div>
//                             <div>
//                                 Option {idx+1}:
//                             </div>
//                             <div>
//                                 {el}
//                             </div>
//                         </div>;
//                     })
//                 }
//             </div>