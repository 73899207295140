import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAdd } from "react-icons/md";
import { IoMdCloudUpload } from "react-icons/io";

function MediaCenter() {
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const [formData, setFormData] = useState({
        id:"",
        sequence: "",
        body: "",
        imageUrl:"",
      });
console.log("formDat",formData);
      const handleMedia = async() => {
        try {
            const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/media-center`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
            console.log("Media Center Fetched successfully", response.data.data)
            setAllData(response.data.data);
        } catch (error) {
            console.log("Error fetching Media Center", error);
        } finally {
          setLoading(false);
      }
      }

      useEffect(() => {
        handleMedia();
      },[]);

      const handleAddDataClick = () => {
        setShowModal(true);
      };
      const handleCloseModal = () => {
        setFormData({
          id:"",
        sequence: "",
        body: "",
        imageUrl:""
        });
        setSelectedFile("");
        setShowModal(false);
        setEditModal(false);
        setDeleteModal(false);
        // setSelected(null);
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
                if (name === "body") {
          if (formData.body === "image" && value !== "image") {
            setFormData({ ...formData, [name]: value, imageUrl: "" });
          } else if (formData.body !== "image" && value === "image") {
            setFormData({ ...formData, [name]: value });
          } else {
            setFormData({ ...formData, [name]: value });
          }
        } else {
          setFormData({ ...formData, [name]: value });
        }
      };
      

      const handleForm = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem("jwt");
      
        if (!authToken) {
          toast.error("No authentication token found. Please log in.");
          return;
        }
      
        try {
          // Step 1: Upload the image if selected
          let imageUrl = '';
          if (selectedFile) {
            const imageFormData = new FormData();
            imageFormData.append('file', selectedFile);
      
            const imageResponse = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/website/file/upload`,
              imageFormData,
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
      
            if (imageResponse.data.uploaded) {
              imageUrl = imageResponse.data.url;
            } else {
              toast.error("Image upload failed");
              return;
            }
          }
      
          // Step 2: Create the service
          const serviceData = {
            sequence: formData.sequence,
            imageType: formData.body,
            imageUrl: formData.body === 'youtube' ? formData.imageUrl : imageUrl,
          };
      
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/media-center`,
            serviceData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
      
          toast.success("Media Center added successfully");
          handleMedia();
          handleCloseModal();
        } catch (error) {
          console.error("Failed adding Media Center", error);
          toast.error(error.response?.data?.message || "Failed adding Media Center");
        }
      };
      
  // edit entry
  const [oldSequene, setOldSequence] = useState("");
  const handleEditClick = async (data) => {
    setOldSequence(data.sequence || "")
        setFormData({
      id: data._id || "",
      sequence: data.sequence || "",
      body: data.imageType || "",
      imageUrl: data.imageUrl || "" 
    });
  
    setEditModal(true);
  };
  
  

  const handleUpdate = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");
  
    if (!authToken) {
      toast.error("No authentication token found. Please log in.");
      return;
    }
  
    if (!formData.id) {
      toast.error("Error updating data");
      return;
    }
  
    // Initial image URL is from formData.imageUrl
    let imageUrl = formData.imageUrl;
  
    // Create a new FormData object to send
    const formDataToSend = new FormData();
    
    // Only append 'sequence' if it has changed
    if (formData.sequence !== oldSequene) {
      formDataToSend.append('sequence', formData.sequence);
    }
  
    formDataToSend.append('imageType', formData.body); // Always include 'body'
    
    // Append the new image if it's changed
    if (selectedFile) {
      const imageFormData = new FormData();
      imageFormData.append('file', selectedFile);
  
      try {
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/website/file/upload`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
  
        if (imageResponse.data.uploaded) {
          imageUrl = imageResponse.data.url; // Update the image URL with the new one
        } else {
          toast.error("Image upload failed");
          return;
        }
      } catch (error) {
        console.error("Failed uploading image", error);
        toast.error("Failed uploading image");
        return;
      }
    }
  
    // Now append the image URL to the form data
    formDataToSend.append('imageUrl', formData.body === 'youtube' ? formData.imageUrl : imageUrl);
  
    // Try sending the updated data
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/media-center/${formData.id}`,
        formDataToSend, // Send the form data with all fields
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data', // Ensure the content type is multipart/form-data
          },
        }
      );
  
      toast.success("Media Center updated successfully");
      handleMedia();
      handleCloseModal();
    } catch (error) {
      console.error("Failed updating Media Center", error);
      const errorMessage = error.response?.data?.message || "Failed updating Media Center";
      toast.error(errorMessage);
    }
  };
  
  
  
  
  
    //   Delete API
    const handleDeleteClick = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
      };

      const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem("jwt");

          const response = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/media-center/${deleteId}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
    
          toast.success("Media Center Deleted Successfully");
          handleMedia();
          setDeleteId("");
          handleCloseModal();
        } catch (error) {
            console.log("Failed deleting Media Center", error);
          toast.error("Failed Deleting Media Center")
        }
      };

      const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          if (file.type.startsWith("image/")) {
            setSelectedFile(file);
          } else {
            toast.error("Please select a valid image file.");
            setSelectedFile(null);
          }
        } else {
          setSelectedFile(null);
        }
      };

      const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu\.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
      };
    
      return (
  <div style={{background: "#eaeaea", width:"100%", height:"100%"  }}>
  <div style={{width:"80%", marginInline:"auto", paddingTop:"50px",background: "#eaeaea"  }}>
  <ToastContainer/>
   <div
        style={{
          display: "flex",
          flexDirection:"column",
          width: "100%",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <h1
          style={{
            display: "flex",
            fontSize: "calc(1.375rem + 1.5vw)",
            fontWeight: "600",
            justifyContent: "start",
            alignItems: "center",

          }}
        >
          Media Center{" "}
        </h1>
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "10px" }}>
          <button style={{
    background: "var(--green)",
    fontSize: "18px",
            border: "none",
            color: "white",
            padding: "5px 20px",
            display:"flex",
            alignItems:"center"
          }}
            onClick={handleAddDataClick}
            >Add Media Center</button>
        </div>
      </div>
    <div style={{ width: "100%", overflow: "scroll", maxHeight: "70vh", paddingInline:"20px", backgroundColor: "#F8F9FA" }}>
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead style={{ position: "sticky", top: "-1px" }}>
        <tr style={{fontSize:"18px", fontWeight:"600", paddingTop:"15px"}}>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Sequence</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Type</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Value</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Action</th>
        </tr>
      </thead>
      <tbody style={{ overflowY: "auto" }}>
      {loading ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>Loading...</td>
                                </tr>
                            ) : allData.length === 0 ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>No data available.</td>
                                </tr>
                            ) : (
                              allData?.map((data, index) => (
          <tr key={index} style={{background:"#F8F9FA"}}>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}>{data?.sequence}</td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat", textTransform:"capitalize" }}>{data?.imageType}</td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>
    {
      data?.imageType === "image" ? (
        <img src={data?.imageUrl} alt="Image" style={{ width: "250px", height: "150px" }} />
      ) : data?.imageType === "youtube" ? (
        <iframe
          width="250"
          height="150"
          src={`https://www.youtube.com/embed/${getYouTubeVideoId(data?.imageUrl)}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <video width="250" height="150" controls>
          <source src={data?.imageUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
  </td>           <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}><div style={{ display: "flex", flexDirection: "row" }}>
              <FaRegEdit size={20} color={"var(--green)"} style={{ marginInline: "5px", display: "flex" }}  onClick={() => handleEditClick(data)}/>
              <RiDeleteBin5Line size={20} color={"red"} style={{ marginLeft: "5px", display: "flex" }} onClick={() => handleDeleteClick(data?._id)}/></div>
            </td>
          </tr>
 ))
)}
      </tbody>
    </table>
  </div>  
        {/* Add new data */}
        {showModal && ( 
       <div
       style={{
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 99,
       }}
     >
       <div
         style={{
           backgroundColor: "white",
           padding: "20px",
           borderRadius: "5px",
           width: "450px",
         }}
       >
         <form onSubmit={(e) => { handleForm(e) }}>
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Add Media</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Sequence</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter sequence"
               value={formData.sequence}
               name="sequence"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
  <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Media Type</label>
  
  <select
    style={{ margin: "10px", padding: "5px", width: "60%", border: "1px solid #d3d3d3", borderRadius: "2px", background: "var(--green)", color: "white", fontWeight: "400" }}
    value={formData.body}
    name="body"
    onChange={handleChange}
    required
  >
    <option value="">Select media type</option>
    <option value="image">Image</option>
    <option value="youtube">YouTube</option>
  </select>
</div>

{formData.body === "image" && (
    <div className="flex flex-col" style={{ height: '200px' }}>
      <label>Image</label>
      <div>
        {selectedFile ? (
          <div style={{ textAlign: 'center' }}>
            <img
              src={URL.createObjectURL(selectedFile)}
              alt="Selected"
              style={{
                width: '100px',
                height: '100px',
                marginTop: '5px',
                borderRadius: '10px',
                marginInline: 'auto',
              }}
            />
            <div className="flex justify-center mt-2">
              <button
                type="button"
                style={{
                  background: 'var(--green)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  cursor: 'pointer',
                }}
                onClick={() => setSelectedFile(null)}
              >
                Change Image
                <input
                  type="file"
                  hidden
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              border: '1px dashed black',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              cursor: 'pointer',
              width: '100%',
              height: '150px',
            }}
          >
            <input
              type="file"
              onChange={handleImageChange}
              accept="image/*"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
            />
            <div className="justify-center flex">
              <IoMdCloudUpload size={50} />
            </div>
            <div className="font-semibold flex justify-center">
              Browse File to upload
            </div>
          </div>
        )}
      </div>
    </div>
  )}

  {formData.body === "youtube" && (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
      <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>YouTube Video Link</label>
      <input
        type="text"
        style={{ margin: "10px", padding: "5px", width: "60%", border: "1px solid #d3d3d3", borderRadius: "2px", background: "var(--green)", color: "white", fontWeight: "400" }}
        placeholder="Enter link"
        value={formData.imageUrl}
        name="imageUrl"
        onChange={handleChange}
        required
      />
    </div>
  )}
     
           <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                  background: "var(--green)",
                 border: "none",
                 borderRadius: "5px",
                 padding: "5px 10px",
                 color: "white",
                 width:"100px",
                 justifyContent:"center",
                 cursor: "pointer"
               }}
               type="submit"
             >
               Add
             </button>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                 background: "#d3d3d3",
                 border: "none",
                 borderRadius: "5px",
                 width:"100px",
                 justifyContent:"center",
                 padding: "5px 10px",
                 color: "black",
                 cursor: "pointer"
               }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     
      )}
        {/* Update data */}
        {editModal && ( 
       <div
       style={{
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 99,
       }}
     >
       <div
         style={{
           backgroundColor: "white",
           padding: "20px",
           borderRadius: "5px",
           width: "450px",
         }}
       >
         <form onSubmit={(e) => { handleUpdate(e) }}>
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Edit Media</div>
     
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Add Media</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Sequence</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter sequence"
               value={formData.sequence}
               name="sequence"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
  <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Media Type</label>
  
  <select
    style={{ margin: "10px", padding: "5px", width: "60%", border: "1px solid #d3d3d3", borderRadius: "2px", background: "var(--green)", color: "white", fontWeight: "400" }}
    value={formData.body}
    name="body"
    onChange={handleChange}
    required
  >
    <option value="">Select media type</option>
    <option value="image">Image</option>
    <option value="youtube">YouTube</option>
  </select>
</div>

{formData.body === "image" && (
    <div className="flex flex-col" style={{ height: '200px' }}>
      <label>Image</label>
      <div>
        {selectedFile ? (
          <div style={{ textAlign: 'center' }}>
            <img
              src={URL.createObjectURL(selectedFile)}
              alt="Selected"
              style={{
                width: '100px',
                height: '100px',
                marginTop: '5px',
                borderRadius: '10px',
                marginInline: 'auto',
              }}
            />
            <div className="flex justify-center mt-2">
              <button
                type="button"
                style={{
                  background: 'var(--green)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  cursor: 'pointer',
                }}
                onClick={() => setSelectedFile(null)}
              >
                Change Image
                <input
                  type="file"
                  hidden
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              border: '1px dashed black',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              cursor: 'pointer',
              width: '100%',
              height: '150px',
            }}
          >
            <input
              type="file"
              onChange={handleImageChange}
              accept="image/*"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
            />
            <div className="justify-center flex">
              <IoMdCloudUpload size={50} />
            </div>
            <div className="font-semibold flex justify-center">
              Browse File to upload
            </div>
          </div>
        )}
      </div>
    </div>
  )}

  {formData.body === "youtube" && (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
      <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>YouTube Video Link</label>
      <input
        type="text"
        style={{ margin: "10px", padding: "5px", width: "60%", border: "1px solid #d3d3d3", borderRadius: "2px", background: "var(--green)", color: "white", fontWeight: "400" }}
        placeholder="Enter link"
        value={formData.imageUrl}
        name="imageUrl"
        onChange={handleChange}
        required
      />
    </div>
  )}
           <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                display: "flex",
                margin: "10px",
                fontSize: "16px",
                background: "var(--green)",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                color: "white",
                width:"100px",
                justifyContent:"center",
                cursor: "pointer"
              }}
               type="submit"
             >
               Update
             </button>
             <button
               style={{
                display: "flex",
                margin: "10px",
                fontSize: "16px",
                background: "#d3d3d3",
                border: "none",
                borderRadius: "5px",
                width:"100px",
                justifyContent:"center",
                padding: "5px 10px",
                color: "black",
                cursor: "pointer"
              }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     
      )}

        {/* Delete Entry */}
        {deleteModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "450px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Are you sure you want to delete?</div>

            <div style={{ display: "flex", justifyContent: "center" }}>
            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "var(--green)", width:"100px",justifyContent:"center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "white", cursor: "pointer" }} onClick={handleDelete}>Delete</button>
            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "#d3d3d3", width:"100px",justifyContent:"center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "black", cursor: "pointer" }} onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
  </div>
  </div>
  )
}

export default MediaCenter