import axios from "axios";
import { getToken, getType } from "./AsyncStorage";
import endUrls from "./ApiUrl";

// https://8n5gyn4hf7.execute-api.ap-south-1.amazonaws.com/api
const url=process.env.REACT_APP_BASE_URL;
export const defaultAxios = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});
defaultAxios.interceptors.request.use((request) => {
    let token = localStorage.getItem('token');
    request.headers["Authorization"] = token ? "Bearer " + token : "";
    return request; 
  });
      


export const getQuestionCategory = async () => {
    const token = await getToken();
    const type = await getType();
    return defaultAxios.get(`${endUrls.viewCategory}`)

    // console.log(`${endUrls.baseUrl}${endUrls.viewCategory}`);
    // axios.get(`${endUrls.baseUrl}${endUrls.viewCategory}`, {
    //   headers: {
    //     "Authorization": `Bearer ${token}`
    //   }
    // }).then((item) => {
    //     setQuestionCategory(item?.data?.data);
    //     console.log(item.data.data,questionCategory);

    // //   if (routes?.params?.gender == "male") {
    // //     let category = item.data.data.filter((ele) => ele.name != "Women")
    // //     setQuestionCategory(category);
    // //   } else {
    // //     setQuestionCategory(item.data.data);
    // //   }
    // }).catch((error) => {
    //   console.log(error)
    //   alert("Error Occurred, Please try again")
    // })
    // console.log(questionCategory);
  }

  // export const getQuestionData = async (catid) =>{
  //   const type = await getType();
  //    const response= defaultAxios.get(`${endUrls.baseUrl}${endUrls.questionnaire}/${type}?questionCategory=${catid}`)
  //    console.log('response',response);
  //    return response;
  // }

  export async function getQuestionData(catid) {
    const type = await getType();
    const res = await defaultAxios.get(`${endUrls.baseUrl}${endUrls.questionnaire}/${type}?questionCategory=${catid}`)
    return res;
  }