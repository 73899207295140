
import React, { useEffect } from 'react';
import { color } from './Color';
import CheckboxContainer from './CheckboxContainer';
import InputContainer from './InputContainer';
import RadioBoxContainer from './RadioBoxContainer';
import BoolContainer from './BoolContainer';

const Questions = props => {
  const {
    item,
    index,
    type,
    questionData,
    setQuestionData,
    reRender,
    setReRender,
  } = props;
  useEffect(() => { }, [item]);

  const isChecked = fieldType => {
    if (type == "I-E" || type == "OI") {
      let dataArr = [];
      if (fieldType == 'checkbox') {
        dataArr = item?.tempOptions?.filter(
          (option, index) => option.checked === true,
        );
      } else if (fieldType == 'input') {
        dataArr = questionData[index].answer;
      } else if (fieldType == 'radio') {
        dataArr = item.answer;
      } else {
        dataArr = item.answer;
      }
      if (dataArr?.length > 0) {
        return true;
      }
      return false;
    } else {
      let dataArr = [];
      if (fieldType == 'checkbox') {
        dataArr = item?.tempHindiOptions?.filter(
          (option, index) => option.checked === true,
        );
      } else if (fieldType == 'input') {
        dataArr = questionData[index].answer;
      } else if (fieldType == 'radio') {
        dataArr = item.answer;
      } else {
        dataArr = item.answer;
      }
      if (dataArr?.length > 0) {
        return true;
      }
      return false;
    }

  };

  return (
    <div style={styles.questionContainer}> 
      {item?.imgUrl?.length >= 0 ? (
        <img
          src={item.imgUrl}
          width={100}
          height={200}
          style={styles.questionImg}
        />
      ) : (
        <></>
      )}
      <div
        style={{ color: color.Black, fontSize: 18,marginBottom :15 }}>
        {type == 'I-E' || type == 'OI'
          ? `Q${index + 1}. ${item.question}`
          : `Q${index + 1}. ${item.hindiQuestion}`}
      </div>
      {item.type == 'checkbox' ? (
        <CheckboxContainer
          type={type}
          item={item}
          index={index}
          questionData={questionData}
          setQuestionData={setQuestionData}
          reRender={reRender}
          setReRender={setReRender}
        />
      ) : item.type == 'input' ? (
        <InputContainer
          type={type}
          item={item}
          index={index}
          questionData={questionData}
          setQuestionData={setQuestionData}
          reRender={reRender}
          setReRender={setReRender}
        />
      ) : item.type == 'radio' ? (
        <RadioBoxContainer
          type={type}
          item={item}
          index={index}
          questionData={questionData}
          setQuestionData={setQuestionData}
          reRender={reRender}
          setReRender={setReRender}
        />
      ) : item.type == 'bool' ? (
        <BoolContainer
          type={type}
          item={item}
          index={index}
          questionData={questionData}
          setQuestionData={setQuestionData}
          reRender={reRender}
          setReRender={setReRender}
        />
      ) : null}
      {isChecked(item.type) ? null : (
        <div style={{ color: 'red', marginLeft: 10, marginTop: 5 }}>This field is required</div>
      )}
    </div>
  );
};

export default Questions;

const styles = {
  questionContainer: {
    marginVertical: 10,
    backgroundColor: color.White,
    padding: 10,
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  questionImg: {
    width: 400,
    height: 500,
    resizeMode: 'cover',
    marginBottom: 20,
    alignSelf: 'center',
  },
}