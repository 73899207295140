import styles from "./Form.module.css";
import {MdMobileScreenShare} from "react-icons/md"
// import OTPInput, { ResendOTP } from "otp-input-react";
import { useState } from "react";
import OtpInput from "../../../elements/OtpInput";
import { Link } from "react-router-dom";

const Form = (props)=>{
    const [OTP, setOTP] = useState("");
    const source = props.source;
    const data = props.data;

    const otpHandler = (e)=>{
        if(OTP.length===6)
        props.otpHandler(OTP);
        else
        alert("invalid OTP");
    }

    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <div className={styles["icon"]}>
                 <MdMobileScreenShare/>
            </div>
            <div className={styles["verify_otp"]}>Verify with OTP</div>
            <div className={styles["sent"]}>Sent to {data}</div>
            <div className={styles["input"]}>
                <OtpInput setOTP={setOTP}/>
            </div>
            <div className={styles["resend"]} onClick={otpHandler}>VERIFY</div>
            <div className={styles["back"]}><Link to="/admin/login">Back to login page</Link></div>
        </div>
    </div>
}
export default Form;