import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import Select from "../../../elements/Select";
import styles from "./ScheduleRow.module.css";

const hours = ['1','2','3','4','5','6','7','8','9','10','11','12'];
const median = ['AM','PM'];
const minutes = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'];

const ScheduleRow = (props)=>{
    const [checked,unchecked] = useState(true);
    // console.log(checked);
    const toggleState = () => unchecked(state=>!state);

    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            {props.mode!="daily" && <>
                <div className={styles["data"]}>
                    <div className={styles["item"]}>Availability</div>
                    <div className={styles["item"]}>
                        <div className={checked?`${styles["check"]} ${styles["checkbox"]}`:`${styles["checkbox"]}`} onClick={toggleState}>
                            <AiOutlineCheck/>
                        </div>
                    </div>
                </div>
                <div className={styles["data"]}>
                    <div className={styles["item"]}>{props.day}</div>
                </div>
            </>}
            <div className={styles["daily"]}>
                <div className={`${styles["data"]} ${styles["rightm"]}`}>
                    <div className={styles["item"]}>From:</div>
                    <div className={styles["item"]}>
                        <input type="number" placeholder="HH" min="0" max="23" value={props.from?props.from[0]:""} onChange={(e)=>{ if(props.setFrom) props.setFrom((state)=>{
                            if(e.target.value=="-" || e.target.value.length>2 || parseInt(e.target.value) <0 || parseInt(e.target.value) > 23) return state;

                            const arr=[...state];
                            arr[0]=e.target.value;
                            return arr;
                        })}}/>
                    </div>
                    <div className={styles["item"]}>:</div>
                    <div className={styles["item"]}>
                        <input type="number" placeholder="MM" min="0" max="59" value={props.from?props.from[1]:""} onChange={(e)=>{ if(props.setFrom) props.setFrom((state)=>{
                            if(e.target.value=="-" || e.target.value.length>2 || parseInt(e.target.value) <0 || parseInt(e.target.value) > 59) return state;

                            const arr=[...state];
                            arr[1]=e.target.value;
                            return arr;
                        })}}/>
                    </div>
                    <div>
                        {/* <Select options={median}/> */}
                    </div>
                </div>
                <div className={styles["data"]}>
                    <div className={styles["item"]}>To:</div>
                    <div className={`${styles["item"]} ${styles["select"]}`}>
                        <input type="number" placeholder="HH" min="0" max="23" value={props.to?props.to[0]:""} onChange={(e)=>{ if(props.setTo) props.setTo((state)=>{
                            if(e.target.value=="-" || e.target.value.length>2 || parseInt(e.target.value) <=0 || parseInt(e.target.value) > 23) return state;

                            const arr=[...state];
                            arr[0]=e.target.value;
                            return arr;
                        })}}/>
                    </div>
                    <div className={styles["item"]}>:</div>
                    <div className={styles["item"]}>
                        <input type="number" placeholder="MM" min="0" max="59" value={props.to?props.to[1]:""} onChange={(e)=>{ if(props.setTo) props.setTo((state)=>{
                            if(e.target.value=="-" || e.target.value.length>2 || parseInt(e.target.value) <0 || parseInt(e.target.value) > 59) return state;

                            const arr=[...state];
                            arr[1]=e.target.value;
                            return arr;
                        })}}/>
                    </div>
                    <div>
                        {/* <Select options={median}/> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default ScheduleRow;