import Table from "./Table";
import styles from "./MemberDetails.module.css";
import Select from "../../elements/Select";
import { useEffect, useRef, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai";
import { FaBackward } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import Question from "./Question";
import { fetchAllUsers, fetchAnswersById, fetchAppointmentById, fetchChartAnswersById, fetchUserById } from "../../../utils/global.apis";
import { useReactToPrint } from 'react-to-print';

const tableDataArr = [
    ["Id", "Name", "Phone No", "Email", "Status", "Country", "Details", "User Answers", "Chart Answers"],
    [1, "Mark Twin", "1234", "mark@gmail.com", 'member', "United Kingdom", "view", "view", "view"],
    [2, "Vijay Sharma", "12338337734", "vijay@gmail.com", 'user', "United States", "view", "view", "view"],
    [3, "Shreya mishra", "1238309228994", "shreya@healinroots.com", 'admin', "India", "view", "view", "view"],
    [4, "Mark Twin", "1234", "mark@gmail.com", 'member', "United Kingdom", "view", "view", "view"],
    [5, "Vijay Sharma", "12338337734", "vijay@gmail.com", 'user', "United States", "view", "view", "view"],
    [6, "Shreya mishra", "1238309228994", "shreya@healinroots.com", 'admin', "India", "view", "view", "view"],
    [7, "Mark Twin", "1234", "mark@gmail.com", 'member', "United Kingdom", "view", "view", "view"],
    [8, "Vijay Sharma", "12338337734", "vijay@gmail.com", 'user', "United States", "view", "view", "view"],
    [9, "Shreya mishra", "1238309228994", "shreya@healinroots.com", 'admin', "India", "view", "view", "view"],
    [10, "Mark Twin", "1234", "mark@gmail.com", 'member', "United Kingdom", "view", "view", "view"],
]

const tablePastPlan = [
    ['PlanId', 'Plan Name', 'From', 'Upto', 'Price'],
    [1, 'Sampurna swasth', '19-12-22', '18-6-23', '25000'],
    [2, 'Sampurna swasth', '19-12-22', '18-6-23', '25000'],
    [3, 'Sampurna swasth', '19-12-22', '18-6-23', '25000'],
    [4, 'Sampurna swasth', '19-12-22', '18-6-23', '25000'],
    [5, 'Sampurna swasth', '19-12-22', '18-6-23', '25000'],
]

const pc_dates = ['19-12-22', '22-09-22', '07-03-22', '19-12-22', '22-09-22', '07-03-22', '19-12-22', '22-09-22', '07-03-22', '19-12-22', '22-09-22', '07-03-22'];

const questions = [
    {
        qno: 1,
        qtype: 'singleselect',
        question: [
            {
                language: 'Hindi',
                question: 'भारत की राजधानी क्या है?',
                options: ['नई दिल्ली', 'मुंबई', 'चेन्नई', 'कोलकाता'],
                answer: ['नई दिल्ली']
            },
        ],
        category: 'Indian - Hindi'
    },
    {
        qno: 2,
        qtype: 'singleselect',
        question: [
            {
                language: 'English',
                question: 'What is captial of India?',
                options: ['New Delhi', 'Mumbai', 'Chennai', 'Kolkata'],
                answer: ['New Delhi']
            },
        ],
        category: 'Indian - English'
    },
    {
        qno: 3,
        qtype: 'text',
        question: [
            {
                language: 'English',
                question: 'What is your name?',
                answer: ['Dharmik']
            },
        ],
        category: 'Non-Indian'
    },
    {
        qno: 4,
        qtype: 'multiselect',
        question: [
            {
                language: 'English',
                question: 'Which cities do you like?',
                options: ['New Delhi', 'Mumbai', 'Chennai', 'Kolkata'],
                answer: ['New Delhi', 'Mumbai']
            },
        ],
        category: 'Indian - English'
    }
];

const questionsChart = [
    {
        qno: 1,
        qtype: 'Yes / No',
        question: [
            {
                language: 'Hindi',
                question: 'Are you lactose intolerent?',
                // options:[''],
                answer: ['Yes']
            },
        ],
        category: 'Indian - Hindi'
    },
    {
        qno: 2,
        qtype: 'Yes / No',
        question: [
            {
                language: 'English',
                question: 'Are you lactose intolerent?',
                // options:['New Delhi','Mumbai','Chennai','Kolkata'],
                answer: ['Yes']
            },
        ],
        category: 'Indian - English'
    },
    {
        qno: 3,
        qtype: 'Yes / No',
        question: [
            {
                language: 'English',
                question: 'Do you drink 5L water?',
                answer: ['No']
            },
        ],
        category: 'Non-Indian'
    },
    {
        qno: 4,
        qtype: 'Yes / No',
        question: [
            {
                language: 'English',
                question: 'Do you sleep 7 hours?',
                // options:['New Delhi','Mumbai','Chennai','Kolkata'],
                answer: ['No']
            },
        ],
        category: 'Indian - English'
    }
];

const formatDataForTable = (arr) => {
    if (!arr) return [];


    return arr.map((el) => {

        return [el?.referenceId, el.name, el.isParent ? "Parent" : `Child`, el.phone ? el.phone : el?.parent?.phone, 'view', 'view', 'view', el.email ? el.email : el?.parent?.email, el.nationality];
    });
}



const formatPlansForTable = (arr) => {
    if (!arr) return [];

    return arr.map(el => {
        return [el.membershipId?._id, el.membershipId?.name, el?.validFrom, el?.validTo, el?.membershipId?.price];
    })
}

// {
//     qno:1,
//     qtype:'Yes / No',
//     question:[
//         {
//             language:'Hindi',
//             question:'Are you lactose intolerent?',
//             // options:[''],
//             answer:['Yes']
//         },
//     ],
//     category:'Indian - Hindi'
// },
const formatAnswersArr = (arr) => {
    let answerList = []
    for (let i = 0; i < arr.length; i++) {
        let el = arr[i]
        if (el.question) {
            let qtype = "Yes / No";
            if (el.question.type == "input") qtype = "Text";
            if (el.question.type == "radio") qtype = "Radio";
            if (el.question.type == "checkbox") qtype = "Checkbox";
            if (el.question.type == "bool") qtype = "Yes / No";

            let cat;
            if (el.question.category == "I") cat = "Indian";
            if (el.question.category == "B") cat = "Both";
            if (el.question.category == "OI") cat = "Non - Indian";

            let ques = [];
            // if(el.question.language=="E"){
            ques = [
                {
                    language: 'English',
                    question: el.question.question,
                    options: el.question.options,
                    answer: [el.answer]
                }
            ]
            // }
            // else if(el.question.language=="H"){
            //     ques=[
            //         {
            //             language:'Hindi',
            //             question:el.question.question,
            //             options:el.question.options,
            //             answer:[el.answer]
            //         }
            //     ]
            // }
            // else if(el.question.language=="B"){
            //     ques=[
            //         {
            //             language:'English',
            //             question:el.question.question,
            //             options:el.question.options,
            //             answer:[el.answer]
            //         },
            //         {
            //             language:'Hindi',
            //             question:el.question.hindiQuestion,
            //             options:el.question.hindiOptions,
            //             answer:[el.hindiAnswer]
            //         },
            //     ];
            // }
            answerList.push({
                qno: el?._id,
                qtype,
                question: ques,
                category: cat
            })
        }
    }
    return answerList
}
const MemberDetails = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [redirect, setRedirect] = useState(false)
    const [showDetails, setShowDetails] = useState(false);
    const [showCQ, setShowCQ] = useState(false);
    const [showCA, setShowCA] = useState(false);
    const [status, setStatus] = useState("Member");
    const [pageNo, setPageNo] = useState(1);
    const [answers, setAnswers] = useState([]);
    const [chartAnswers, setChartAnswers] = useState([]);
    const [searchBox, setSearchBox] = useState("");
    const [reportUrl, setReportUrl] = useState("")
    const chart = useRef(null)
    const question = useRef(null)
    const handleSearch = (e) => setSearchBox(e.target.value);


    const onStatusChange = (e) => setStatus(e.target.value);

    const [tableData, setTableData] = useState([
        ["Name", "User Type", "Phone No", "Email", "Status", "Country", "Details", "User Answers", "Chart Answers"],
    ]);
    const [pastPlans, setPastPlans] = useState([
        ['Id', 'Plan Name', 'From', 'Upto', 'Price'],
    ]);

    const [ids, setIds] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    // console.log("ud",userDetails);
    const [pc_dates, setPCDates] = useState([]);

    // console.log(ids);

    useEffect(() => {
        // console.log(searchParams.get('id'));
        fetchUserById(searchParams.get('id'))
            .then(res => {
                if (!res.data.data) throw `something went wrong!`;

                // console.log(res.data.data);
                let status;
                if (res.data.data.isAdmin) status = "Admin";
                if (res.data.data.isSubscribed) status = "Member";
                else status = "User";

                setUserDetails({ ...res.data.data, status });

                setPastPlans(state => [
                    ['Id', 'Plan Name', 'From', 'Upto', 'Price']
                    , ...formatPlansForTable(res.data.data.membership)]);
            })
            .catch(err => console.log(err));

        fetchAppointmentById(searchParams.get('id'))
            .then(res => {
                if (!res.data.data) throw `something went wrong!`;

                // console.log(res);
                const dates = res.data.data.map(el => el.date);
                // console.log(dates);
                setPCDates(dates);
            })
            .catch(err => console.log(err));

        fetchAnswersById(searchParams.get('id'))
            .then(res => {
                if (!res.data.data) {
                    throw `something went wrong!`
                }
                setReportUrl(res.data.data.imgUrl)
                console.log("ANSWER", formatAnswersArr(res.data.data.answers))
                setAnswers(formatAnswersArr(res.data.data.answers));
                console.log("answers", answers)
            })
            .catch(err => {
                setAnswers([])
            });

        fetchChartAnswersById(searchParams.get('id'))
            .then(res => {
                if (!res.data.data) throw `something went wrong!`;

                console.log(res.data.data.answers);
                setChartAnswers(formatAnswersArr(res.data.data.answers));
            }).catch(err => console.log(err));
    }, [searchParams.get('id'), redirect]);


    const prevPageHandler = (e) => {
        if (pageNo > 1)
            setPageNo(state => state - 1);
    }
    const nextPageHandler = (e) => {
        if (tableData.length == 11)
            setPageNo(state => state + 1);
    }

    // console.log(tableData);
    // console.log("showCq",showCQ);

    useEffect(() => {
        let stat;
        if (status == "Member") stat = "subscribed";
        else if (status == "User") stat = "unsubscribed";
        else if (status == "Admin") stat = "admin";

        let searchQuery = {
            currentPage: pageNo,
            pageSize: 10,
            userType: stat,
        }

        if (searchBox) {
            searchQuery["search"] = true
            searchQuery["name"] = searchBox
        }

        fetchAllUsers(searchQuery)
            .then(res => {
                // console.log(res.data.data);
                if (!res.data.data) throw `something went wrong!`;

                setTableData(state => {
                    let arr = [["User Id", "Name", "User Type", "Phone No", "User Answers", "Chart Answers", "Details", "Email", "Country"]];
                    let formated = formatDataForTable(res.data.data);
                    // console.log(formated,arr);
                    arr = [...arr, ...formated];
                    // console.log(arr);
                    return arr;
                });
                setIds(state => {
                    return res.data.data.map(el => el._id);
                });
            })
            .catch(err => console.log(err));
    }, [status, searchBox, pageNo]);

    const options = {
        orientation: 'portrait',
        unit: 'in',
        format: "A3"
    };

    const handlePrint = useReactToPrint({
        content: () => question.current,
    });

    const handleChartPrint = useReactToPrint({
        content: () => chart.current,
    });

    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            {!showDetails && !showCQ && !showCA && <><div className={styles["headings"]}>
                <h1>User management</h1>
            </div>
                <div className={styles["table"]}>
                    <div className={styles["options"]}>
                        <div className={styles["option"]} style={{ width: "200px", marginRight: "20px" }}>
                            <input value={searchBox} onChange={handleSearch} placeholder="Search username" style={{ width: "200px" }} />
                        </div>
                        <div className={styles["option"]} style={{ width: "170px" }}>
                            <Select options={["Member", "User", "Admin"]} onChange={onStatusChange} />
                        </div>
                    </div>
                    <div className={styles["inner"]}>
                        <Table notFoundMsg="No users found" setRedirect={setRedirect} redirect={redirect} ids={ids} data={tableData} some="abs" setShowDetails={setShowDetails} makeLastBtn={true} setShowCQ={setShowCQ} setShowCA={setShowCA} pageNo={pageNo} nextPageHandler={nextPageHandler} prevPageHandler={prevPageHandler} pagination={false} />
                    </div>
                    <div style={{ color: '#eaeaea' }}>s</div>
                </div></>}
            {
                showDetails && <>
                    <div className={styles["back"]} >
                        <div className={styles["close"]} onClick={(e) => setShowDetails(false)}><AiOutlineArrowLeft /></div>
                    </div>
                    <div className={styles["scontent"]}>
                        <div className={styles["m_heading"]}>
                            <h3>User details</h3>
                        </div>
                        <div className={styles["userdetails"]}>
                            <div className={styles["ud_content"]}>
                                <div className={styles["data"]}>
                                    <span><b>Id:</b> {userDetails?.referenceId}</span>
                                    <span><b>Phone No:</b> {userDetails.phone}</span>
                                    <span><b>Status:</b> {userDetails.status}</span>

                                </div>
                                <div className={styles["data"]}>
                                    <span><b>Name:</b> {userDetails.name}</span>
                                    <span><b>Email:</b> {userDetails.email}</span>
                                    <span><b>Country:</b> {userDetails.nationality}</span>
                                </div>
                            </div>
                            <div className={styles["pc_data"]}>
                                <div className={styles["pc_head"]}>
                                    <b>Past consultations:</b>
                                </div>
                                <div className={styles["tags"]}>
                                    {
                                        pc_dates && pc_dates.map((el, idx) => {
                                            return <div className={styles["tag"]}>{el && el.split("T")[0]}</div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles["membership_details"]}>
                            {/* <div className={styles["m_heading"]}>
                                <h5>Membership details</h5>
                            </div> */}
                            <div>
                                <Table notFoundMsg="No past plans found" data={pastPlans} />
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                showCQ && <><div className={styles["back"]} style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <div className={styles["close"]} onClick={(e) => setShowCQ(false)}><AiOutlineArrowLeft /></div>
                    {/* <Pdf targetRef={question} filename="code-example.pdf" options={options} x={.5} y={.5} scale={0.8}>
                        {({ toPdf }) =>  <div onClick={toPdf}>
                        <img
                            src={require("../../../assets/printer.png")}
                            style={{
                                width: "50px",
                                height: "50px"
                            }}
                        />
                    </div>}
                    </Pdf> */}
                    <div onClick={() => { handlePrint() }}>
                        <img
                            src={require("../../../assets/printer.png")}
                            style={{
                                width: "50px",
                                height: "50px"
                            }}
                        />
                    </div>
                </div>
                    <div className={styles["scontent"]} ref={question}>
                        <div className={styles["m_heading"]}>
                            <h3>User Answers</h3>
                        </div>
                        <div ref={chart}>
                            <div className={styles["userdetails"]}>
                                <div className={styles["ud_content"]}>
                                    <div className={styles["data"]}>
                                        <span><b>Id:</b> {userDetails?.referenceId}</span>
                                        <span><b>Phone No:</b> {userDetails.phone}</span>

                                    </div>
                                    <div className={styles["data"]}>
                                        <span><b>Name:</b> {userDetails.name}</span>
                                        <span><b>Email:</b> {userDetails.email}</span>
                                    </div>
                                </div>
                                {
                                    reportUrl ?
                                        <div style={{ width: "100%" }}><b>Reports:</b><a href={reportUrl} style={{ marginLeft: "10px" }}>download</a></div>
                                        :
                                        <div style={{ width: "100%" }}><b>Reports:</b><span style={{ marginLeft: "10px" }}>Not Available</span></div>

                                }
                            </div>
                            {answers && answers.map((el, idx) => <div style={{ backgroundColor: 'white', marginBottom: '20px' }}><Question {...answers[idx]} qno={idx + 1} key={idx} hideCollapse={true} /></div>)}
                        </div>
                    </div>
                </>
            }
            {
                showCA && <><div className={styles["back"]} style={{
                    display: "flex",
                    justifyContent: "space-between"
                }} >
                    <div className={styles["close"]} onClick={(e) => setShowCA(false)}><AiOutlineArrowLeft /></div>
                    <div onClick={() => { handleChartPrint() }}>
                        <img
                            src={require("../../../assets/printer.png")}
                            style={{
                                width: "50px",
                                height: "50px"
                            }}
                        />
                    </div>
                </div>
                    <div className={styles["scontent"]} ref={chart}>
                        <div className={styles["m_heading"]}>
                            <h3>Chart Answers</h3>
                        </div>
                        <div>
                            <div className={styles["userdetails"]}>
                                <div className={styles["ud_content"]}>
                                    <div className={styles["data"]}>
                                        <span><b>Id:</b> {userDetails?.referenceId}</span>
                                        <span><b>Phone No:</b> {userDetails.phone}</span>

                                    </div>
                                    <div className={styles["data"]}>
                                        <span><b>Name:</b> {userDetails.name}</span>
                                        <span><b>Email:</b> {userDetails.email}</span>
                                    </div>
                                </div>
                            </div>
                            {chartAnswers && chartAnswers.map((el, idx) => <div style={{ backgroundColor: 'white', marginBottom: '20px' }}><Question {...chartAnswers[idx]} qno={idx + 1} key={idx} hideCollapse={true} /></div>)}
                        </div>
                    </div>
                </>
            }
        </div>
    </div>
}
export default MemberDetails;



{/* <div>
                                <div>
                                    <span>Plan id: 628252</span>
                                    <span>Plan name: Sampurna swasth</span>
                                </div>
                                <div>
                                    <span>Plan from: 12-10-22</span>
                                    <span>Plan upto: 19-10-23</span>
                                </div>
                            </div> */}