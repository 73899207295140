import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChartQuestionSequence, updateChartQuestionSequenceByType } from '../../../../store/ChartQuestionSequence/ChartQuestionSequence.slice';
import { setQuestionSequence, updateQuestionSequenceByType } from '../../../../store/QuestionSequence/QuestionSequence.slice';

import Question from './Question';
import styles from "./Questions.module.css";
 
const App = (props) => {

  //refs used for drag drop feature
  const dragItem = useRef();
  const dragOverItem = useRef();

  //list of questions i.e questionare fetched from global state
  const list = useSelector(state=>state.chartSequence.chartQuestionSequence);

  //dispatch hook
  const dispatch = useDispatch();

  
  //replace questionare in global state with val
  function setList (val){
    dispatch(setChartQuestionSequence(val));
  }

 
  //drag drop feature functions
  const dragStart = (e, position) => {
    if(!props.draggable) return ;

    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    if(!props.draggable) return ;

    dragOverItem.current = position;
  };
  const drop = (e) => {
    if(!props.draggable) return ;

    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);

    if(!props.ctype) return;

    let type;
    if(props.ctype=="Indian - English") type="I-E";
    if(props.ctype=="Indian - Hindi") type="I-H";
    if(props.ctype=="Non - Indian") type="OI";

    // dispatch(updateQuestionSequenceByType({questions:copyListItems,type}))
    // .then(res=>{
    //   // console.log(res);
    //   props.refresh();
    // })
    // .catch(err=>console.log(err));
  };
 
  function updateSequenceHandler(e){
    let type;
    if(props.ctype=="Indian - English") type="I-E";
    if(props.ctype=="Indian - Hindi") type="I-H";
    if(props.ctype=="Non - Indian") type="OI";
    
    dispatch(updateChartQuestionSequenceByType({questions:list,type}))
    .then(res=>{
      // console.log(res);
      alert("sequence updated succesfully");
      props.refresh();
    })
    .catch(err=>console.log(err));
  }


  //markup
  return (
    <>
    {
    list&&list.map((item, index) => {
    if(props.type!==item.qtype&&props.type!="All") return <></>;

    return (
      <div 
        className={styles["question_box"]}
        onDragStart={(e) => dragStart(e, index)}
        onDragEnter={(e) => dragEnter(e, index)}
        onDragEnd={drop}
        key={index}
        draggable={props.draggable?true:false}>
          <Question 
        {...item}
        qno={index+1}
        refresh={props.refresh}
        />
      </div> 
      )})}
      <div className={styles["btn"]} onClick={updateSequenceHandler}>Save</div>
    </>
  );
};
export default App;




//   const [list, setList] = useState([
//     {
//         qno:1,
//         qtype:'singleselect',
//         question:[
//             {
//                 language:'Hindi',
//                 question:'भारत की राजधानी क्या है?',
//                 options:['नई दिल्ली','मुंबई','चेन्नई','कोलकाता'],
//             },
//         ],
//         category:'Indian - Hindi'
//     },
//     {
//         qno:2,
//         qtype:'singleselect',
//         question:[
//             {
//                 language:'English',
//                 question:'What is captial of India?',
//                 options:['New Delhi','Mumbai','Chennai','Kolkata'],
//             },
//         ],
//         category:'Indian - English'
//     },
//     {
//         qno:3,
//         qtype:'text',
//         question:[
//             {
//                 language:'English',
//                 question:'What is your name?',
//             },
//         ],
//         category:'Non-Indian'
//     },
//     {
//         qno:4,
//         qtype:'multiselect',
//         question:[
//             {
//                 language:'English',
//                 question:'Which cities do you like?',
//                 options:['New Delhi','Mumbai','Chennai','Kolkata'],
//             },
//         ],
//         category:'Indian - English'
//     }
//   ]);