//npm module imports
import {createBrowserRouter} from "react-router-dom";
import Login from "../components/pages/Login";
import Otp from "../components/pages/Otp";
import Dashboard from "../components/pages/Dashboard";

//custom imports
import Home from "./../components/pages/Home/Home";
import RefundPolicy from "../components/pages/RefundPolicy";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import TNC from "../components/pages/TNC";
import Register from "../components/pages/User/Register/Register";
import Form from "../components/pages/User/Test/Form";
import BlogDetail from "../components/sections/Blog/BlogDetail";
import AllBlogs from "../components/sections/Blog/AllBlogs";
import Header from "../components/sections/HeaderSection/Header";
import StaticPages from "../components/sections/UserStaticPages/StaticPages";
import ProtectedRoute from "./ProtectedRoute";
// import Form from "../components/pages/User/Register/Form";
export default  createBrowserRouter([
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/admin/login",
      element: <Login/>,
    },
    {
      path: "/admin/otp",
      element: <Otp/>,
    },
    {
      path:"/admin/dashboard",
      element: <ProtectedRoute element={<Dashboard />} />, 
    },
    {
      path:"/RefundPolicy",
      element:<RefundPolicy/>
    },
    {
      path:"/PrivacyPolicy",
      element:<PrivacyPolicy/>
    },
    {
      path:"/TermsAndConditions",
      element:<TNC/>
    },
    {
      path:"/register",
      element:<Register/>
    },
    {
      path:"/blog/:blogId/:postId",
      element:<BlogDetail/>
    },
    {
      path:"/all-blogs",
      element:<AllBlogs/>
    },
    {
      path:"/form",
      element:<Form/>
    },
    {
      path:"/staticPages/:slug",
      element:<StaticPages/>
    },
  ]);