import { useSearchParams } from "react-router-dom";
import styles from "./Table.module.css";
const TableHeader = (props) => {
    return <thead class="thead-dark">
        <tr>
            {props.data && props.data.map((el, idx) => {
                console.log(el)
                return <th className={styles["member-heading"]} scope="col">{el}</th>
            })}
        </tr>
    </thead>
}

const TableRow = (props) => {
    return <tr>
        {
            props.data && props.data.map((el, idx) => {
                if (idx == 0) return
                if (idx == 5) return <td className={styles["member-data"]} onClick={() => {
                    props.redirectToCA(props.rowId)
                }}><div className={styles["link"]}>{el}</div></td>
                return <td className={styles["member-data"]}>{el}</td>
            })
        }
    </tr>
}



const AttemptTable = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const redirectToCA = (e) => {
        console.log(e)

        setSearchParams(state => {
            let obj = { ...state };
            // console.log(obj);
            obj.id = e;
            obj.activeIdx = searchParams.get('activeIdx');
            return obj;
        });

        props.setShowCA(true);
        props.setRedirect(!props.redirect)
    }
    let rowData = props.data ? props.data : [];

    if (props.data.length <= 1) return <div>{props.notFoundMsg}</div>;

    return <div className={styles["container"]}>
        <div className={styles["wrapper"]}>
            <div className={styles["content"]}>
                <table class="table table-light">
                    <TableHeader data={["User Id", "Name", "Phone", "Website User", "Answers", "Email", "Date"]} />
                    <tbody>
                        {
                            rowData && rowData.map((el, idx) => {
                                return <TableRow data={el} rowId={el[0]} redirectToCA={redirectToCA} setRedirect={props.setRedirect} redirect={props.redirect} setShowDetails={props.setShowDetails} makeLastBtn={props.makeLastBtn} setShowCQ={props.setShowCQ} setShowCA={props.setShowCA} id={props.ids ? props.ids[idx] : 0} />
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
        {props.pagination == false && <div className={styles["options"]}>
            <div className={styles["left"]}></div>
            <div className={styles["right"]}>
                {props.pageNo > 1 && <div onClick={props.prevPageHandler}>Prev</div>}
                <div onClick={props.nextPageHandler}>Next</div>
            </div>
        </div>}
    </div>
}
export default AttemptTable