import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
    deleteMembershipByIdHandler,
    fetchMembershipByTypeHandler, updateMembershipByTypeHandler
} from "./Membership.thunk";

const INIT_STATE = {
  Membership:[]
};

export const fetchMembershipByType = createAsyncThunk(
  "fetchMembershipByTypeHandler",
  fetchMembershipByTypeHandler
);

// export const updateMembershipByType = createAsyncThunk(
//     "updateMembershipByTypeHandler",
//     updateMembershipByTypeHandler
// );

// export const deleteMembershipById = createAsyncThunk(
//     "deleteChartQuestioFromnSequenceByIdHandler",
//     deleteMembershipByIdHandler
// );

const MembershipSlice = createSlice({
    name: "Membership",
    initialState: INIT_STATE,
    reducers: {
      setMembership: (state, action) => {
        state.Membership = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchMembershipByType.fulfilled, (state, payload) => {
          state.Membership = payload.payload;
          return state;
        })
        .addCase(fetchMembershipByType.rejected, (state) => {
          return state;
        });
        // .addCase(updateMembershipByType.fulfilled, (state, payload) => {
        //     state.Membership = payload.payload;
        //     return state;
        // })
        // .addCase(updateMembershipByType.rejected, (state) => {
        //     return state;
        // })
        // .addCase(deleteMembershipById.fulfilled, (state, payload) => {
        //     let idx=[...state.Membership].findIndex(el=>el._id==payload.payload);
        //     if(idx==-1) return state;
        //     let arr= [...state.Membership];
        //     arr.splice(idx,1);
        //     state.Membership=arr;
        //     return state;
        // })
        // .addCase(deleteMembershipById.rejected, (state) => {
        //     return state;
        // });
    },
  });
  
  export const { setMembership } = MembershipSlice.actions;
  
  export default MembershipSlice.reducer;