import { useEffect, useState } from "react";
import Select from "../../elements/Select";
import Card from "./Card";
import styles from "./MembershipManagement.module.css";
import Form from "./Form";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartQuestionSequenceByType } from "../../../store/ChartQuestionSequence/ChartQuestionSequence.slice";
import { fetchMembershipByType } from "../../../store/Memberships/Membership.slice";

const CategoryData = ["Indian","Non-Indian"];
const MembershipManagement = (props)=>{
    const [showForm,setShowForm] = useState(false);
    const [category,setCategory] = useState("Indian");
    const [refresh,setRefresh] = useState(true);
    
    //used to refetch questionare
    const refreshFn = ()=>setRefresh(state=>!state);
    const dispatch = useDispatch();

    const categoryChangeHandler = (e)=>{
        setCategory(e.target.value);
    };

    const toggleShowForm = (e)=>setShowForm(state=>!state);


    //to fetch entire questionare
    useEffect(()=>{
        let cat;
        if(category=="Indian") cat="I";
        if(category=="Non-Indian") cat="OI";

        dispatch(fetchMembershipByType(cat)); 

    },[category,refresh]);

    const memberships = useSelector(state=>state.membership.Membership);
    

    return <div className={styles["container"]}>
    {showForm && <div className={styles["form"]}>
        <div className={styles["form_inner"]}>
            <Form onClose={toggleShowForm} refreshFn={refreshFn}/>
        </div>
    </div>}
<div className={styles["content"]}>
    <div className={styles["headings"]}>
        <h1>Membership management</h1>
    </div>
    <div className={styles["inner"]}>
        <div className={styles["upper"]}>
            <div className={styles["nav"]}>
                <div className={styles["left"]}>
                    <div className={styles["category"]}>
                        <Select options={CategoryData} onChange={categoryChangeHandler}/>
                    </div>
                    {/* <div className={styles["category"]}>
                        <Select options={AnswerTypeData} onChange={onAnswerChange}/>
                    </div> */}
                </div>
                <div className={styles["right"]}>
                    <div onClick={toggleShowForm}>Add Membership</div>
                </div>
            </div>
        </div>
        <div className={styles["lower"]}>
            <div className={styles["cards"]}>
                {memberships && memberships.map(el=>{
                    return <div className={styles["card"]}>
                    <Card data={el} refreshFn={refreshFn}/>
                </div>
                })}
                {/* <div className={styles["card"]}>
                    <Card/>
                </div> */}
            </div>
        </div>
    </div>
</div>
</div>
}
export default MembershipManagement;