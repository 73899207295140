import React, { useEffect, useState } from 'react'
import { color } from './Color';
import InputContainer from './InputContainer';
import endUrls from './ApiUrl';
import { getToken } from './AsyncStorage';
import axios from 'axios';

const CheckboxContainer = (props) => {
  const { type, item, questionData, index, setQuestionData, reRender, setReRender } = props;
  const [showTextInput, setShowTextInput] = useState(false)
  useEffect(() => {

  }, [item.tempOptions])
  const addQuestion = async (item, index) => {
    let questionIndex = questionData.findIndex(ele => ele._id == item.question)
    if (questionIndex != -1) return
    const token = await getToken();
    axios.get(`${endUrls.baseUrl}${endUrls.viewQuestion}/${item.question}`,
      {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then((res) => {
        let data = res.data.data
        if (data.type == "bool") {
          data["options"] = [
            {
              text: "Yes"
            },
            {
              text: "No"
            },
          ]
          data["answer"] = ""
        } else if (data.type == "checkbox") {
          data.tempOptions = []
          data.tempHindiOptions = []
          data.options.forEach((e) => {
            data.tempOptions.push({
              text: e,
              checked: false
            })
          })
          data.hindiOptions.forEach((e) => {
            data.tempHindiOptions.push({
              text: e,
              checked: false
            })
          })
          data["answer"] = ""
        } else {
          data["answer"] = ""
        }
        let newQuestionData = [...questionData]
        newQuestionData.splice(index + 1, 0, data)
        setQuestionData(newQuestionData)
        setReRender(!reRender)
      }).catch((err) => {
        console.log(err)
      })
  }
  const removeQuestion = (item) => {
    let newQuestionData = questionData
    let index = newQuestionData.findIndex(ele => ele._id == item.question)
    if (index == -1) return
    newQuestionData.splice(index, 1)
    setQuestionData(newQuestionData)
    setReRender(!reRender)
  }
  return (
    <div  style={styles.checkboxContainer}>
      {
        type == "I-E" || type == "OI" ?
          item.tempOptions.map((option, i) => {
            return (
              <div className='d-flex gap-2 my-2 '>
                <input type="checkbox"
                  checked={option.checked ? true : false}
                  color={color.Primary}
                  onChange={() => {
                    const data = questionData
                    data[index].tempOptions[i].checked = !data[index].tempOptions[i].checked
                    if (data[index].tempOptions[i].checked == true && data[index].tempOptions[i].text == "Other") {
                      setShowTextInput(true)
                    } else if (data[index].tempOptions[i].checked == false && data[index].tempOptions[i].text == "Other") {
                      setShowTextInput(false)
                    }
                    if (item?.normalQuestion?.option.length != 0) {
                      let check=false
                      item?.tempOptions.map(ele=>{
                        if(ele.text==item?.normalQuestion?.option && item?.normalQuestion?.option !== ''  && ele.checked)
                        {
                          check=true
                        }
                      })
                      if(check)
                      {
                        removeQuestion(item.normalQuestion)
                      }else{
                        addQuestion(item.normalQuestion, index)
                      }
                    }
                    setQuestionData([...data]);
                    setReRender(!reRender)
                  }}
                />
                <span >{option.text}</span>
              </div>
            )
          })
          :
          item.tempHindiOptions.map((option, i) => {
            return (
              <div >
                <input type='checkbox'
                  checked={option.checked ? true : false}
                  color={color.Primary}
                  onChange={(event) => {
                    const data = questionData
                    data[index].tempHindiOptions[i].checked = !data[index].tempHindiOptions[i].checked
                    if (data[index].tempHindiOptions[i].checked == true && data[index].tempHindiOptions[i].text == "अन्य") {
                      setShowTextInput(true)
                    } else if (data[index].tempHindiOptions[i].checked == false && data[index].tempHindiOptions[i].text == "अन्य") {
                      setShowTextInput(false)
                    }
                    let check = false
                    if(item?.normalQuestion?.option.length != 0)
                    {
                      data[index].tempHindiOptions.map((ele)=>{
                        if((ele.text == "नहीं" || ele.text == "कभी नहीं" || ele.text == "सामान्य " || ele.text == "सामान्य") && ele.checked == true)
                        {
                          check=true
                        }
                      })
                    }
                    if (check) {
                      removeQuestion(item.normalQuestion)
                    } else {
                      addQuestion(item.normalQuestion, index)
                    }
                    setQuestionData(data);
                    setReRender(!reRender)
                  }}
                />
                <span style={styles.checkboxText}>{option.text}</span>
              </div>
            )
          })
      }
      {
        showTextInput ?
          <InputContainer type={type} item={item} index={index} questionData={questionData} setQuestionData={setQuestionData} reRender={reRender} setReRender={setReRender} />
          :
          <></>
      }
    </div>
  )
}

export default CheckboxContainer;

const styles ={
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: "center"
  },
  checkboxText: {
    fontSize: 18,
    color: color.Black
  }
}