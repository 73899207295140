import { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import styles from "./Option.module.css";


const Option = (props)=>{


    //remove this option
    function onCloseHandler (e){
        props.onClose(props.idx);
    }
    return <div className={styles["container"]}>
        <div className={styles["heading"]}>Option {props.idx}</div>
        <div className={styles["inp"]}>
            <input type="text" value={props.option?props.option:""} onChange={(e)=>{

            //update options arr on option change
            props.setOptions(state=>{

                let arr=state;

                if(arr.length<=props.idx-1)
                arr=[...arr,Array(props.idx-arr.length).fill("")];

                arr[props.idx-1]=e.target.value;

                return [...arr];
            })}}/>
        </div>
        <div className={styles["close"]} onClick={onCloseHandler}>
            <AiOutlineCloseCircle/>
        </div>
    </div>
}

export default Option;