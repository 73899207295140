import axios from "axios";
import styles from "./Data.module.css";
import { useEffect, useState } from "react";
const Data = (props)=>{
    const [allData,setAllData] = useState({})
    const handleHeroSection = async() => {
        try {
          const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/website/home`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          const allData = response.data.data.homePageData;
          setAllData(allData);
        } catch (error) {
          console.log("Error fetching Hero Section", error);
        } 
      };
    
      useEffect(() => {
        handleHeroSection();
      },[]);
    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <div className={styles["box"]}>
                <h6>{allData?.happy_users} <br/>Happy</h6>
                <p>
                    Users
                </p>
            </div>
            <div className={styles["box"]}>
                <h6>{allData?.rating} <br/>Star</h6>
                <p>
                    Rated
                </p>
            </div>
            <div className={styles["box"]}>
                <h6>{allData?.patients} <br/>Happy</h6>
                <p>
                    Patients
                </p>
            </div>
            <div className={styles["box"]}>
                <h6>{allData?.service?.split(" ")[0]} <br/>{allData?.service?.split(" ")[1]} </h6>
                <p>
                    Service
                </p>
            </div>
        </div>
    </div>;
}
export default Data;