import { formatQuestions } from "../../utils/generic";
import { getMembership } from "../../utils/global.apis";

export const fetchMembershipByTypeHandler = async (__, thunkApi) => {
    try {
      const res = await getMembership(__);
      // console.log(res);
      return res.data.data;
      return {};
    } catch (err) {
      return Promise.reject(err);
    }
  };

  // export const updateMembershipByTypeHandler = async (__, thunkApi) => {
  //   try {
  //     const res = await updateMembership(__.questions,__.type);

  //     return formatQuestions(res.data.data.questionSequence,__);

  //   } catch (err) {
  //     return Promise.reject(err);
  //   }
  // };

  // export const deleteChartQuestionFromSequenceByIdHandler = async (__, thunkApi) => {
  //   try {
  //     const res = await MembershipDelete(__);
  //     return __;
  //   //   return formatQuestions(res.data.data.questionSequence,__);
  //   } catch (err) {
  //     return Promise.reject(err);
  //   }
  // };