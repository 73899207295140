import { useLocation, useNavigate } from "react-router-dom";
import { verifyOtp } from "../../../utils/global.apis";
import Form from "./Form";
import styles from "./Otp.module.css";


const Otp = (props)=>{
    const navigate = useNavigate();

    const search = useLocation().search;
    const source = new URLSearchParams(search).get('source');
    const data = new URLSearchParams(search).get('data');
    
    const otpHandler = async (value)=>{
        try{
        const res = await verifyOtp(value,source,data);

        if (res.data.success) {
            const { isAdmin } = res.data.data;
            if (isAdmin) {
                localStorage.setItem("jwt", res.data.data.token);
                navigate('/admin/dashboard');
            } else {
                alert("Not authorized"); 
                navigate('/admin/login')
            }
        } else {
            alert("Invalid OTP");
        }
    } catch (err) {
        alert("Oops! Something went wrong");
    }
    }
    return <div className={styles["container"]}>
        <Form otpHandler={otpHandler} source={source} data={data}/>
    </div>
}
export default Otp;