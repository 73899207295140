import React from 'react'
import { color } from './Color';

const InputContainer = (props) => {
    const { type, item, questionData, index, setQuestionData, reRender, setReRender } = props;
    return (
        <>
            {
                item.subType == "number" ?
                    <input  type="number"
                        placeholder={type=="I-H"?"उत्तर दर्ज करें":"Enter Answer"}
                        placeholderTextColor="#666666"
                        style={{
                            borderWidth: 1,
                            width: "95%",
                            borderColor: color.Black,
                            borderRadius: 10,
                            paddingLeft: 10,
                            marginTop: 5,
                            backgroundColor: "#FFF",
                            alignSelf: "center",
                            padding:20,
                        }}
                        onChange={(Text) => {
                            let isnum = /^\d+$/.test(Text)
                            if(Text.length>0 && isnum==false)
                            {
                                alert("Only numbers are allowed")
                                const data = questionData;
                                questionData[index].answer = ""
                                setQuestionData(data)
                                setReRender(!reRender)
                            }else{
                                const data = questionData;
                                questionData[index].answer = Text.target.value
                                setQuestionData(data)
                                setReRender(!reRender)
                            }
                        }}
                        value={item.answer}
                    />
                    :
                    <input type="text"
                    placeholder={type=="I-H"?"उत्तर दर्ज करें":"Enter Answer"}
                        placeholderTextColor="#666666"
                        style={{
                            borderWidth: 1,
                            width: "95%",
                            borderColor: color.Black,
                            borderRadius: 10,
                            paddingLeft: 10,
                            marginTop: 5,
                            backgroundColor: "#FFF",
                            alignSelf: "center",
                            padding:20,
                        }}
                        multiline={true}
                        numberOfLines={3}
                        onChange={(Text) => {
                            const data = questionData;
                            questionData[index].answer = Text.target.value
                            setQuestionData(data)
                            setReRender(!reRender)
                        }}
                        value={item.answer}
                    />
            }
        </>
    )
}

export default InputContainer

// const styles = StyleSheet.create({})