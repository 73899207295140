import React from "react";
import styles from "./slidercontainer.module.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SliderContainer({ images }) {
  const settings = {
    infinite: true,
    // dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div
      className={styles["image-container"]}
    >
      <Slider {...settings}>
        {images.map((item) => (
          <img
            src={item.src}
            key={item.id}
          />
        ))}
      </Slider>
    </div>
  );
}

export default SliderContainer;
