import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
    deleteQuestionFromSequenceByIdHandler,
    fetchQuestionSequenceByTypeHandler, updateQuestionSequenceByTypeHandler
} from "./QuestionSequence.thunk";

const INIT_STATE = {
  questionSequence:[]
};

export const fetchQuestionSequenceByType = createAsyncThunk(
  "fetchQuestionSequenceByTypeHandler",
  fetchQuestionSequenceByTypeHandler
);

export const updateQuestionSequenceByType = createAsyncThunk(
    "updateQuestionSequenceByTypeHandler",
    updateQuestionSequenceByTypeHandler
);

export const deleteQuestionFromSequenceById = createAsyncThunk(
    "deleteQuestioFromnSequenceByIdHandler",
    deleteQuestionFromSequenceByIdHandler
);

const questionSequenceSlice = createSlice({
    name: "questionSequence",
    initialState: INIT_STATE,
    reducers: {
      setQuestionSequence: (state, action) => {
        state.questionSequence = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchQuestionSequenceByType.fulfilled, (state, payload) => {
          state.questionSequence = payload.payload;
          return state;
        })
        .addCase(fetchQuestionSequenceByType.rejected, (state) => {
          return state;
        })
        .addCase(updateQuestionSequenceByType.fulfilled, (state, payload) => {
            state.questionSequence = payload.payload;
            return state;
        })
        .addCase(updateQuestionSequenceByType.rejected, (state) => {
            return state;
        })
        .addCase(deleteQuestionFromSequenceById.fulfilled, (state, payload) => {
            let idx=[...state.questionSequence].findIndex(el=>el._id==payload.payload);
            if(idx==-1) return state;
            let arr= [...state.questionSequence];
            arr.splice(idx,1);
            state.questionSequence=arr;
            return state;
        })
        .addCase(deleteQuestionFromSequenceById.rejected, (state) => {
            return state;
        });
    },
  });
  
  export const { setQuestionSequence } = questionSequenceSlice.actions;
  
  export default questionSequenceSlice.reducer;